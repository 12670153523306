import { RoutePageType } from "@routes/interfaces"
import ArmazemCadastro from "./pages/ArmazemCadastro"
import ArmazemEditar from "./pages/ArmazemEditar"
import ArmazemLista from "./pages/ArmazemLista"

const armazemPages: RoutePageType[] = [
    {key: 'armazemLista', element: ArmazemLista},
    {key: 'armazemCadastro', element: ArmazemCadastro},
    {key: 'armazemEditar', element: ArmazemEditar},
]

export default armazemPages