import { mdiWarehouse } from "@mdi/js"
import { RouteType } from "@routes/interfaces"

const armazemRoutes: RouteType[] = [
    {
        key: 'armazem',
        path: 'armazem',
        title: 'Armazém',
        icon: mdiWarehouse,
        userVerification: true,
        subRoutes: [
            {key: 'armazemLista', path: '', title: 'Armazéns', userVerification: true},
            {key: 'armazemCadastro', path: 'cadastro', title: 'Novo Armazém', userVerification: true},
            {key: 'armazemEditar',path: 'editar/:guidArmazem', title: 'Editar Armazém', showMenu: false, userVerification: true },
        ]
    }
]

export default armazemRoutes