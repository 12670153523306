import React, { useEffect } from 'react'
import './styles.scss'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import Loader, { LoaderContainer } from '@components/Loader'
import { setCurrentData } from '@modules/agendamento/reducers/agendamentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import Painel from './components/Painel'
import FormFilter from './components/FormFilter'

const AgendamentoCadastroHorario: React.FC = () => {

    const dispatch = useAppDispatch()
    const { armazens } = useAppSelector(s => s.armazem)
    const { armazemBloqueios } = useAppSelector(s => s.armazemEditar)
    const { requestGetArmazens } = useAppSelector(s => s.requestArmazem)
    const { requestGetArmazemBloqueios } = useAppSelector(s => s.requestArmazemEditar)
    const { agendamentos, currentData } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentos } = useAppSelector(s => s.requestAgendamento)

    const CR_ARMAZENS = conditionaRender(requestGetArmazens, armazens, true)
    const CR_AGENDAMENTOS = conditionaRender(requestGetAgendamentos, agendamentos)
    const CR_BLOQUEIOS = conditionaRender(requestGetArmazemBloqueios, armazemBloqueios)

    useEffect(() => {
        dispatch(setCurrentData(''))

        return () => {
            dispatch(setCurrentData(''))
        }
    }, [dispatch])

    return(

        <>
            <Section>
                <SectionBox title = "Cadastro de horário">
                    <FormFilter />
                    {(CR_ARMAZENS.LOADING || CR_AGENDAMENTOS.LOADING || CR_BLOQUEIOS.LOADING) && (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    )}
                    {(CR_ARMAZENS.DATA && CR_AGENDAMENTOS.DATA && CR_BLOQUEIOS.DATA && !!currentData) && (
                        <>
                            <Painel />
                        </>
                    )}
                </SectionBox>
            </Section>
        </>

    )

}

export default AgendamentoCadastroHorario