import { RouteType } from "../../../routes/interfaces"

const authRoutes: RouteType[] = [
    {
        key: 'login',
        path: 'login',
        title: 'Login',
        type: 'form',
    },
    {
        key: 'login',
        path: 'login/:u/:x',
        title: 'Login',
        type: 'form',
    },
    {
        key: 'register',
        path: 'register',
        title: 'Register',
        type: 'center',
    },
    {
        key: 'forgot-password',
        path: 'forgot-password',
        title: 'Forgot Password',
        type: 'center',
    },
    {
        key: 'access-request',
        path: 'solicitacao-acesso',
        title: 'Nova solicitação de acesso',
        type: 'center',
    }
]

export default authRoutes