import React from 'react'
import createClassName from '@utils/createClassName'
import { ContainerProps } from './types'
import './styles.scss'

const Container: React.FC <ContainerProps> = ({
    children,
    direction = 'column',
    align = 'start',
    justify = 'start',
    width,
    height,
    padding = true,
    margin,
    gap,
    className,
    style
}) => {

    const containerClasses = createClassName(
        ['containerWrapper', `direction-${direction}`, `align-${align}`, `justify-${justify}`],
        [padding && 'padding', margin && 'margin', !!className && className]
    )

    return(

        <div
            className = {containerClasses}
            style = {Object.assign({}, {width, height, gap}, style)}
        >{children}</div>

    )

}

export default Container