import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { requestAddMenuActions, requestRemoveMenuActions, requestUpdateMenuActions } from "../reducers/menu/requestMenuReducer"
import { getModulos } from "./moduloController"

export async function addModuloMenu<T>(dispatch: DispatchType, body: T, handleClose: () => void){
    initRequest(dispatch, requestAddMenuActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo-menu/salvar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('addMenu', dispatch, response, requestAddMenuActions)
    .then(() => {
        getModulos(dispatch)
        handleClose()
    })
}

export async function updateModuloMenu<T>(dispatch: DispatchType, body: T, handleClose: () => void){
    initRequest(dispatch, requestUpdateMenuActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo-menu/editar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('updateMenu', dispatch, response, requestUpdateMenuActions)
    .then(() => {
        getModulos(dispatch)
        handleClose()
    })
}

export async function removeModuloMenu<T>(dispatch: DispatchType, body: T, handleClose: () => void){
    initRequest(dispatch, requestRemoveMenuActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo-menu/deletar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('removeMenu', dispatch, response, requestRemoveMenuActions)
    .then(() => {
        getModulos(dispatch)
        handleClose()
    })
}