import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { useParams } from 'react-router'
import { mdiCheck } from '@mdi/js'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { maskCnpj } from '@constants/masks'
import { setDesbloqueio, setMultipleDesbloqueio } from '@modules/armazem/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setOpenModalDesbloquear } from '../../reducers/armazenEditarReducer'
import { formatMask } from '@utils/format'

const ModalDesbloquear: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazem } = useAppSelector(s => s.armazem)
    const { openModalDesbloquear, bloqueioValues, filterData, selectedArmazens } = useAppSelector(s => s.armazemEditar)
    const { requestSetArmazemDesbloqueio } = useAppSelector(s => s.requestArmazemEditar)
    const { guidArmazem } = useParams()

    const isMultiples = selectedArmazens.length > 0

    return(

        <Modal title = "Desbloquear agendamento" width = {400} open = {openModalDesbloquear} setOpen = {setOpenModalDesbloquear}>
            {isMultiples && <span className = "modalBoxMessage">{`Deseja desbloquear (${selectedArmazens.length}) janelas?`}</span>}
            {(!!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja desbloquear o agendamento na janela ${bloqueioValues?.nroDoca} às ${bloqueioValues?.horario}?`}</span>}
            {(!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja desbloquear todo o agendamento do dia ${filterData.split('-').reverse().join('/')}?`}</span>}
            
            <Formik
                initialValues = {{
                    nroDocumento: ''
                }}
                onSubmit = {v => {}}
            >
                {({ values, handleChange }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            {armazem!.flagRegraAgendamento === 'S' && (
                                <div className = "w-full mt-4">
                                    <Form.Row columns = {1}>
                                        <Form.Group
                                            label = "Vincular CNPJ Transportadora?"
                                            inputID = "nroDocumento"
                                            inputName = "nroDocumento"
                                            mask = {maskCnpj}
                                            handleChange = {handleChange}
                                        />
                                    </Form.Row>
                                </div>
                            )}

                            <ButtonGroup>
                                <Button
                                    label = "Cancelar"
                                    onClick = {() => dispatch(setOpenModalDesbloquear(false))}
                                />
                                <Button
                                    icon = {mdiCheck}
                                    loading = {requestSetArmazemDesbloqueio.loading}
                                    label = "Desbloquear"
                                    status = "success"
                                    onClick = {() => {
                                        if(isMultiples) setMultipleDesbloqueio(dispatch, guidArmazem!, filterData, selectedArmazens, userData!, formatMask(values.nroDocumento))
                                        else setDesbloqueio(dispatch, guidArmazem!, filterData, bloqueioValues, userData!, formatMask(values.nroDocumento))
                                    }}
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </Modal>

    )

}

export default ModalDesbloquear