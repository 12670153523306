import * as yup from 'yup'

export const formValues = {
    nroDocumento: '',
    razaoSocial: '',

    nomeResponsavel: '',
    email: '',
    cpfResponsavel: '',
    telefone: '',

    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    estado: '',
    cidade: '',

    atendimentoManhaEntrada: '',
    atendimentoManhaSaida: '',
    atendimentoTardeEntrada: '',
    atendimentoTardeSaida: '',
}

export const formSchema = yup.object().shape({
    nroDocumento: yup.string().required('Campo obrigatório'),
    nomeResponsavel: yup.string().required('Campo obrigatório'),
    cpfResponsavel: yup.string().required('Campo obrigatório'),
    razaoSocial: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório'),
    telefone: yup.string().required('Campo obrigatório'),
    endereco: yup.string().required('Campo obrigatório'),
    numero: yup.string().required('Campo obrigatório'),
    complemento: yup.string().required('Campo obrigatório'),
    bairro: yup.string().required('Campo obrigatório'),
    cep: yup.string().required('Campo obrigatório'),
    estado: yup.string().required('Campo obrigatório'),
    cidade: yup.string().required('Campo obrigatório'),
    atendimentoManhaEntrada: yup.string().required('Campo obrigatório'),
    atendimentoManhaSaida: yup.string().required('Campo obrigatório'),
    atendimentoTardeEntrada: yup.string().required('Campo obrigatório'),
    atendimentoTardeSaida: yup.string().required('Campo obrigatório'),
})