export const formModuloFields = [
    {
        data: [
            {inputID: 'nome', inputName: 'nome', label: 'Nome'},
            {inputID: 'path', inputName: 'path', label: 'Caminho'},
            
        ]
    }, {
        data: [
            {inputID: 'ordem', inputName: 'ordem', label: 'Ordem', inputType: 'number'},
            {inputID: 'icone', inputName: 'icone', label: 'Ícone'},
        ]
    }
]