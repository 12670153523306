import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"

interface State {
    requestAddMenu: ResponseDefault<any>
    requestUpdateMenu: ResponseDefault<any>
    requestRemoveMenu: ResponseDefault<any>
}

const initialState: State = {
    requestAddMenu: responseInitialValues,
    requestUpdateMenu: responseInitialValues,
    requestRemoveMenu: responseInitialValues,   
}

const requestMenuSlice = createSlice({
    name: 'requestMenu',
    initialState,
    reducers: {
        setRequestAddMenuData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestAddMenu.data = action.payload
            state.requestAddMenu.loading = false
            state.requestAddMenu.error = false
        },
        setRequestAddMenuLoading: (state) => {
            state.requestAddMenu.loading = true
            state.requestAddMenu.error = false
        },
        setRequestAddMenuError: (state) => {
            state.requestAddMenu.loading = false
            state.requestAddMenu.error = true
        },
        setRequestAddMenuMessage: (state, action: PayloadAction<string>) => {
            state.requestAddMenu.message = action.payload
        },
        resetRequestAddMenu: (state) => {
            state.requestAddMenu = {...responseInitialValues}
        },

        setRequestUpdateMenuData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateMenu.data = action.payload
            state.requestUpdateMenu.loading = false
            state.requestUpdateMenu.error = false
        },
        setRequestUpdateMenuLoading: (state) => {
            state.requestUpdateMenu.loading = true
            state.requestUpdateMenu.error = false
        },
        setRequestUpdateMenuError: (state) => {
            state.requestUpdateMenu.loading = false
            state.requestUpdateMenu.error = true
        },
        setRequestUpdateMenuMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateMenu.message = action.payload
        },
        resetRequestUpdateMenu: (state) => {
            state.requestUpdateMenu = {...responseInitialValues}
        },

        setRequestRemoveMenuData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveMenu.data = action.payload
            state.requestRemoveMenu.loading = false
            state.requestRemoveMenu.error = false
        },
        setRequestRemoveMenuLoading: (state) => {
            state.requestRemoveMenu.loading = true
            state.requestRemoveMenu.error = false
        },
        setRequestRemoveMenuError: (state) => {
            state.requestRemoveMenu.loading = false
            state.requestRemoveMenu.error = true
        },
        setRequestRemoveMenuMessage: (state, action: PayloadAction<string>) => {
            state.requestRemoveMenu.message = action.payload
        },
        resetRequestRemoveMenu: (state) => {
            state.requestRemoveMenu = {...responseInitialValues}
        },
    }
})

export const requestAddMenuActions = {
    data: requestMenuSlice.actions.setRequestAddMenuData,
    loading: requestMenuSlice.actions.setRequestAddMenuLoading,
    error: requestMenuSlice.actions.setRequestAddMenuError,
    message: requestMenuSlice.actions.setRequestAddMenuMessage,
    reset: requestMenuSlice.actions.resetRequestAddMenu,
}

export const requestUpdateMenuActions = {
    data: requestMenuSlice.actions.setRequestUpdateMenuData,
    loading: requestMenuSlice.actions.setRequestUpdateMenuLoading,
    error: requestMenuSlice.actions.setRequestUpdateMenuError,
    message: requestMenuSlice.actions.setRequestUpdateMenuMessage,
    reset: requestMenuSlice.actions.resetRequestUpdateMenu,
}

export const requestRemoveMenuActions = {
    data: requestMenuSlice.actions.setRequestRemoveMenuData,
    loading: requestMenuSlice.actions.setRequestRemoveMenuLoading,
    error: requestMenuSlice.actions.setRequestRemoveMenuError,
    message: requestMenuSlice.actions.setRequestRemoveMenuMessage,
    reset: requestMenuSlice.actions.resetRequestRemoveMenu,
}

export default requestMenuSlice.reducer