import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { maskPlaca } from '@constants/masks'
import { editAgendamento } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const FormEdit: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { agendamentoLista, currentData } = useAppSelector(s => s.agendamento)
    const { painelEditValues } = useAppSelector(s => s.agendamentoEditar)
    const { requestEditAgendamento } = useAppSelector(s => s.requestAgendamento)

    const TextGroup: React.FC <{title: string, text: string | number}> = ({ title, text }) => (
        <div className = "textGroup">
            <span>{title}</span>
            <span>{text}</span>
        </div>
    )

    return(

        <>
            {!!agendamentoLista && (
                <Formik
                    initialValues = {{
                        id: agendamentoLista.id,
                        nomeMotorista: agendamentoLista.nomeMotorista,
                        peso: agendamentoLista.peso,
                        docMotorista: agendamentoLista.docMotorista,
                        placaVeiculo: agendamentoLista.placa,
                        placa2: agendamentoLista.placa2,
                        placa3: agendamentoLista.placa3,
                        guid: agendamentoLista.guid
                    }}
                    onSubmit = {v => {
                        editAgendamento(dispatch, v, painelEditValues!, currentData, userData!)
                    }}
                >
                    {({ handleSubmit, handleChange, errors }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <div className = "grid grid-cols-2 gap-2">
                                        <TextGroup
                                            title = "Armazém"
                                            text = {agendamentoLista.descricaoArmazem}
                                        />
                                        <TextGroup
                                            title = "Cliente"
                                            text = {agendamentoLista.nmCliente}
                                        />
                                    </div>
                                </Form.Row>
                                {[10, 13].includes(userData!.grupoAcesso.id) && (
                                    <Form.Row columns = {3}>
                                        <Form.Group
                                            label = "Peso"
                                            error = {!!errors.peso}
                                            inputID = "peso"
                                            inputName = "peso"
                                        />
                                    </Form.Row>
                                )}
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "Nome motorista"
                                        error = {!!errors.nomeMotorista}
                                        inputID = "nomeMotorista"
                                        inputName = "nomeMotorista"
                                    />
                                    <Form.Group
                                        label = "Documento motorista"
                                        error = {!!errors.docMotorista}
                                        inputID = "docMotorista"
                                        inputName = "docMotorista"
                                    />
                                </Form.Row>
                                <Form.Row columns = {3}>
                                    <Form.Group
                                        label = "Placa"
                                        error = {!!errors.placaVeiculo}
                                        inputID = "placaVeiculo"
                                        inputName = "placaVeiculo"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Placa 2"
                                        error = {!!errors.placa2}
                                        inputID = "placa2"
                                        inputName = "placa2"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Placa 3"
                                        error = {!!errors.placa3}
                                        inputID = "placa3"
                                        inputName = "placa3"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                                <ButtonGroup>
                                    <Button
                                        label = "Editar agendamento"
                                        loading = {requestEditAgendamento.loading}
                                        onClick = {handleSubmit}
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default FormEdit