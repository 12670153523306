import React from 'react'
import { useParams } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { saveNotaFiscal } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import convertBase64 from '@utils/convertBase64'
import { formSchema, formValues } from './config'
import { FormAddNotaFiscalProps } from './types'

const FormAddNotaFiscal: React.FC <FormAddNotaFiscalProps> = ({ nextStep = false }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { currentArmazem, notasFiscais } = useAppSelector(s => s.agendamento)
    const { requestSaveNotaFiscal } = useAppSelector(s => s.requestAgendamento)
    const { guid } = useParams()

    return(

        <>
            <Formik
                initialValues = {formValues}
                validationSchema = {formSchema}
                onSubmit = {v => saveNotaFiscal(dispatch, v, guid!, userData!)}
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <FormikForm>
                        <Form.Container>
                            <Form.Row columns = {2}>
                                <Form.Group
                                    label = "N° Nota"
                                    inputID = "nroNota"
                                    inputName = "nroNota"
                                />
                                <Form.Group
                                    label = "N° Série"
                                    inputID = "nroSerie"
                                    inputName = "nroSerie"
                                />
                            </Form.Row>
                            <Form.Row columns = {1}>
                                <ButtonGroup>
                                    <div className = "btnWrapper">
                                        <label htmlFor = "arquivo" className = "btn default">Selecionar arquivo</label>
                                        <input
                                            id = "arquivo" 
                                            name = "arquivo" 
                                            type = "file" 
                                            accept = "image/*,application/pdf"
                                            onChange = {async e => {
                                                setFieldValue('extensao', `.${e.target.files![0].name.split('.')[1]}`)
                                                setFieldValue('arquivo', await convertBase64(e.target.files![0]))
                                            }}
                                        />
                                    </div>
                                    <Button
                                        label = "Adicionar NF"
                                        loading = {requestSaveNotaFiscal.loading}
                                        status = {!!values.arquivo ? "info" : 'disabled'}
                                        onClick = {handleSubmit}
                                    />
                                    {nextStep && (
                                        <Button
                                            label = "Avançar"
                                            status = {notasFiscais?.length === 0 ? "disabled" : "success"}
                                            disabled = {notasFiscais?.length === 0}
                                            link = {`/agendamento/cadastroHorario/${guid}/${armazens?.find(f => f.id === currentArmazem as any)?.guid}/${currentArmazem}`}
                                        />
                                    )}
                                </ButtonGroup>
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormAddNotaFiscal