import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RelatorioAgendamento } from "../interfaces/RelatorioAgendamento"

interface State {
    relatorioAgendamentos: RelatorioAgendamento[] | null
}

const initialState: State = {
    relatorioAgendamentos: null,
}

const relatorioSlice = createSlice({
    name: 'relatorio',
    initialState,
    reducers: {
        setRelatorioAgendamentos: (state, action: PayloadAction<RelatorioAgendamento[]>) => {
            state.relatorioAgendamentos = action.payload
        },
    }
})

export const { setRelatorioAgendamentos } = relatorioSlice.actions
export default relatorioSlice.reducer