import { UserData } from "../interfaces/UserData"
import * as R from '../reducers/requestAuthReducer'
import { setAuthLogin } from "../reducers/authReducer"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse } from "@utils/response"
import formValues from "../pages/Login/components/FormLogin/values"
import ENV from "@env/index"

export default async function handleLogin(dispatch: DispatchType, setRedirect: Function, values: ReturnType<typeof formValues>){
    dispatch(R.requestSendAuthLogin.reset())
    dispatch(R.requestSendAuthLogin.loading())

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.APP_LOGIN_ENDPOINT}/usuario/login`
    const body = { ...values, idAplicativo: 1 }
    const response = await request.post<ResponsePattern<UserData>>({endpoint, body})

    handleResponse("handleLogin", dispatch, response, R.requestSendAuthLogin, options)
    .then(data => {
        dispatch(setAuthLogin(data))
        setRedirect(true)
    })
}