import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Section from '@components/Page/Section'
import Container from '@components/Container'
import SectionBox from '@components/Page/Section/box'
import Loader, { LoaderContainer } from '@components/Loader'
import TableNfCnpj from '@modules/agendamento/components/NotaFiscal/Table'
import ListNotaFiscal from '@modules/agendamento/components/NotaFiscal/List'
import { getBloqueios } from '@modules/armazem/controllers'
import FormAddNotaFiscal from '@modules/agendamento/components/NotaFiscal/FormAdd'
import FormSearchNotaFiscalCnpj from '@modules/agendamento/components/NotaFiscal/FormSearch'
import { setCurrentAgendamentoLista, setCurrentData } from '@modules/agendamento/reducers/agendamentoReducer'
import { getAgendamentoLista, getAgendamentoNotasFiscaisCnpj, getAgendamentos } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import Painel from './components/Painel'
import FormEdit from './components/FormEdit'
import FormFilter from './components/FormFilter'
import { setPainelEditValues } from './reducers/agendamentoEditarReducer'

const AgendamentoEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { armazemBloqueios } = useAppSelector(s => s.armazemEditar)
    const { requestGetArmazens } = useAppSelector(s => s.requestArmazem)
    const { requestGetArmazemBloqueios } = useAppSelector(s => s.requestArmazemEditar)
    const { agendamentos, agendamentosLista, agendamentoLista, currentData } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentos, requestGetAgendamentosLista, requestGetAgendamentoLista } = useAppSelector(s => s.requestAgendamento)
    const { idAgendamento } = useParams()

    const CR_AGENDAMENTOS_LISTA = conditionaRender(requestGetAgendamentosLista, agendamentosLista, true)
    const CR_ARMAZENS = conditionaRender(requestGetArmazens, armazens, true)
    const CR_AGENDAMENTOS = conditionaRender(requestGetAgendamentos, agendamentos)
    const CR_BLOQUEIOS = conditionaRender(requestGetArmazemBloqueios, armazemBloqueios)
    const CR_AGENDAMENTO = conditionaRender(requestGetAgendamentoLista as any, agendamentoLista)

    useEffect(() => {
        if(!!idAgendamento){
            getAgendamentoLista(dispatch, Number(idAgendamento))
        }
    }, [dispatch, idAgendamento])

    useEffect(() => {
        if(!!userData && !!userData.nroDocumento){
            getAgendamentoNotasFiscaisCnpj(dispatch, userData.nroDocumento)
        }
    }, [dispatch, userData])

    useEffect(() => {
        if(!!agendamentosLista && !!idAgendamento){
            const find = agendamentosLista.find(f => f.id === Number(idAgendamento))
            if(!!find) dispatch(setCurrentAgendamentoLista(find))
        }
    }, [dispatch, agendamentosLista, idAgendamento])

    useEffect(() => {
        if(!!agendamentoLista && !!armazens && !!userData){
            const currentArmazem = armazens!.find(f => f.id === agendamentoLista!.idArmazem)!
            const data = agendamentoLista!.dtAgendaFormatada.split(' ')[0].split('/').reverse().join('-')

            dispatch(setPainelEditValues({
                hour: agendamentoLista.dtAgendaFormatada.split(' ')[1],
                dock: agendamentoLista.nrDoca
            }))

            dispatch(setCurrentData(data))
            getAgendamentos(dispatch, { data, idArmazem: currentArmazem.id.toString() }, userData!)
            getBloqueios(dispatch, currentArmazem.guid, data, userData!)
        }
    }, [dispatch, armazens, agendamentoLista, userData])

    return(

        <>
            <Section>
                <SectionBox title = {`Editar Agendamento ${idAgendamento}`}>
                    {(CR_AGENDAMENTO.LOADING || CR_AGENDAMENTOS_LISTA.LOADING || CR_ARMAZENS.LOADING || CR_AGENDAMENTOS.LOADING || CR_BLOQUEIOS.LOADING) && (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    )}
                    {(CR_AGENDAMENTO.DATA && CR_AGENDAMENTOS_LISTA.DATA && CR_ARMAZENS.DATA && CR_AGENDAMENTOS.DATA && CR_BLOQUEIOS.DATA && !!agendamentoLista && !!currentData) && (
                        <>
                            <FormFilter />
                            <Painel />
                        </>
                    )}
                </SectionBox>
                {(CR_AGENDAMENTO.DATA && CR_AGENDAMENTOS_LISTA.DATA && CR_ARMAZENS.DATA && !!agendamentoLista) && (
                    <>
                        <Container padding = {false}>
                            <Container direction = "row" align = "start" justify = "between" gap = {24} padding = {false}>
                                <SectionBox title = "Notas Fiscais" padding = {false}>
                                    <ListNotaFiscal />
                                    <FormAddNotaFiscal />
                                </SectionBox>
                                <SectionBox
                                    title = "Notas fiscais por CNPJ"
                                    padding = {false}
                                    right = {
                                        <FormSearchNotaFiscalCnpj
                                            handleSearch = {v => {
                                                getAgendamentoNotasFiscaisCnpj(dispatch, v)
                                            }}
                                            handleReset = {() => {}}
                                        />
                                    }
                                >
                                    <TableNfCnpj />
                                </SectionBox>
                            </Container>
                        </Container>
                        <SectionBox title = "Informações">
                            <FormEdit />
                        </SectionBox>
                    </>
                )}
            </Section>
        </>

    )

}

export default AgendamentoEditar