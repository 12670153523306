import React from 'react'
import { View, StyleSheet, Text } from "@react-pdf/renderer"
import { SignatureProps } from './types'

const PdfSignature: React.FC <SignatureProps> = ({ label }) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        line: {
            width: '100%',
            borderTop: "1px solid black",
        },
        label: {
            fontSize: 6,
        }
    })

    return(

        <View style = {styles.container}>
            <View style = {styles.line} />
            <Text style = {styles.label}>{label}</Text>
        </View>

    )

}

export default PdfSignature