import * as yup from 'yup'

export const formValues = {
    idArmazem: '',
    dtInicio: '',
    dtFim: '',
}

export const formSchema = yup.object().shape({
    idArmazem: yup.string().required('Campo obrigatório'),
    dtInicio: yup.string().required('Campo obrigatório'),
    dtFim: yup.string().required('Campo obrigatório'),
})