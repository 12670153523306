import React from 'react'
import { mdiPlus } from '@mdi/js'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import Button from '@components/Button'
import ListArmazens from './components/ListArmazens'

const ArmazemLista: React.FC = () => {

    return(

        <>
            <Section>
                <SectionBox
                    title = "Armazéns"
                    padding = {false}
                    right = {
                        <Button
                            icon = {mdiPlus}
                            status = "success"
                            label = "Novo Armazém"
                            link = "/armazem/cadastro"
                        />
                    }
                >
                    <ListArmazens />
                </SectionBox>
            </Section>
        </>

    )

}

export default ArmazemLista