import { RoutePageType } from "../../../routes/interfaces"
import Login from "../pages/Login"
import Register from "../pages/Register"
import ForgotPassword from "../pages/ForgotPassword"
import AccessRequest from "./AccessRequest"

const authPages: RoutePageType[] = [
    {key: 'login', element: Login},
    {key: 'register', element: Register},
    {key: 'forgot-password', element: ForgotPassword},
    {key: 'access-request', element: AccessRequest},
]

export default authPages