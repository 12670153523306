import React from 'react'
import { Formik, Form as FormikForm, Field } from 'formik'
import { useAppDispatch } from '../../../../redux/hooks'
import handleRegister from '../../scripts/handleRegister'

const Register: React.FC = () => {

    const dispatch = useAppDispatch()

    return(

        <>
            <Formik
                initialValues = {{email: '', password: ''}}
                onSubmit = {async values => await handleRegister(dispatch)}
            >
                <FormikForm style = {{display: 'flex', flexDirection: 'column'}}>
                    <Field type = "email" name = "email" />
                    <Field type = "password" name = "password" />
                    <button type = "submit">Criar Conta</button>
                </FormikForm>
            </Formik>
        </>

    )

}

export default Register