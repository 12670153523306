import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { mdiEye, mdiPlusCircle } from '@mdi/js'
import { createTableConfig } from '@components/Table/scripts/tableConfig'
import { TableColumn, TableOption, TableRow, TablePaginator } from '@components/Table'
import { gerarDanfe, getNotasFiscais, vincularAgendamentoNotaFiscal } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const TableNfCnpj: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { notasFiscaisCnpj } = useAppSelector(s => s.agendamento)
    const { requestGetNotasFiscaisCnpj } = useAppSelector(s => s.requestAgendamento)
    
    const [tableConfig] = useState(createTableConfig(
        'nfCnpj',
        ['N° Nota', 'N° Série', 'Opções']
    ))

    const { guid } = useParams()
        
    const CR = conditionaRender(requestGetNotasFiscaisCnpj, notasFiscaisCnpj, true)

    const getData = () => {
        getNotasFiscais(dispatch, { guid }, userData!)
    }

    return(

        <>
            <TablePaginator
                data = {notasFiscaisCnpj}
                config = {tableConfig}
                conditionalRender = {CR}
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.nrNf}</TableColumn>
                        <TableColumn>{item.serieNf}</TableColumn>
                        <TableColumn width = "100px">
                            <TableOption
                                icon = {mdiEye}
                                onClick = {() => {
                                    gerarDanfe(dispatch, item.xml)
                                }}
                            />
                            <TableOption
                                icon = {mdiPlusCircle}
                                onClick = {() => {
                                    vincularAgendamentoNotaFiscal(
                                        dispatch,
                                        userData!,
                                        {
                                            guid,
                                            xml: item.xml,
                                            nroNota: item.nrNf,
                                            nroSerie: item.serieNf
                                        }
                                        , getData
                                    )
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableNfCnpj