import React, { useContext } from 'react'
import Loader from '@components/Loader'
import TableContext from '@components/Table/context'
import TableColumn from '../Container/Column'

const TableLoader: React.FC = () => {

    const { columns, omitColumns } = useContext(TableContext)

    return(

        <tr>
            <TableColumn className = "center" colSpan = {columns.length - omitColumns.length}>
                <Loader />
            </TableColumn>
        </tr>

    )

}

export default TableLoader