import React from 'react'
import { Link } from 'react-router-dom'
import { CircleSpinner } from 'react-spinners-kit'
import Icon from '@mdi/react'
import { TableOptionProps } from '../Container/types'

const TableOption: React.FC <TableOptionProps> = ({
    loading,
    icon,
    status,
    href = false,
    link,
    target,
    onClick
}) => {

    return(
        <>
            {(loading && (
                <div className = "tableOption loading">
                    <CircleSpinner size={20} color="grey" />
                </div>
            )) || (!!link && (
                <div className = {`tableOption${status ? ` status ${status}` : ''}`} onClick = {e => onClick && onClick(e)}>
                    {(!!href && (
                        <a href = {link} target = {target ?? '_self'}>
                            <Icon path = {icon} size = "20px" />
                        </a>
                    )) || (
                        <Link to = {link} target = {target ?? '_self'}>
                            <Icon path = {icon} size = "20px" />
                        </Link>
                    )}
                </div>
            )) || (
                <div className = {`tableOption${status ? ` status ${status}` : ''}`} onClick = {e => onClick && onClick(e)}>
                    <Icon path = {icon} size = "20px" />
                </div>
            )}
        </>
    )

}

export default TableOption