import React from 'react'
import { Disclosure } from "@headlessui/react"
import { PlusCircleIcon } from "@heroicons/react/20/solid"
//@ts-ignore
import Ask1 from '@modules/suporte/pdf/ask1.pdf'
//@ts-ignore
import Ask4 from '@modules/suporte/pdf/ask4.pdf'
import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'

const ViewFaq: React.FC = () => {

    return(

        <>
            <div className="h-full max-w-full">
                {/* <h1 className=" font-sans	 text-center font-bold text-3xl	mb-10 text-slate-600	">
                    
                    PERGUNTAS FREQUENTES
                </h1> */}
                <div id = "faqBox" className="w-fullrounded-2xl bg-white p-2">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className=" border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>Como criar um agendamento na plataforma ? </span>
                                    <PlusCircleIcon
                                        className={`${
                                            open ? "rotate-180 transform" : ""
                                        } h-6 w-6 text-[#35175d]		`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Baixe nosso manual, onde nele ensina cada etapa de como
                                    criar um agendamento e realizar consultas.
                                    <a
                                        className="text-rose-600 font-semibol ml-2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={Ask1}
                                    >clique aqui</a>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>
                                        É obrigatório inserir nota fiscal no agendamento?
                                    </span>
                                    <PlusCircleIcon
                                        className={`${
                                            open ? "rotate-180 transform" : ""
                                        } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Sim! Pois assim nossa equipe consegue se preparar pra
                                    receber o veiculo e realizar os procedimentos.
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 text-base border-b-1 flex w-full justify-between rounded-lg px-4 py-3 text-left   font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>
                                    Consigo alterar o agendamento depois de criado?
                                    </span>
                                    <PlusCircleIcon
                                    className={`${
                                        open ? "rotate-180 transform" : ""
                                    } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                Sim, é possivel! Logo após o agendamento ter sido criado, na tela inicial aparecera a opção pra alteração, clique nesse menu indicado.
                                <Icon path = {mdiPencil} size = "20px" />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>Como imprimir um agendamento ou excluir?</span>
                                    <PlusCircleIcon
                                    className={`${
                                        open ? "rotate-180 transform" : ""
                                    } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Manual
                                    <a
                                        className="text-rose-600 font-semibold ml-2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={Ask4}
                                    >
                                    clique aqui
                                    </a>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>
                                    Sem grade disponivel, tem como abrir exceções?
                                    </span>
                                    <PlusCircleIcon
                                    className={`${
                                        open ? "rotate-180 transform" : ""
                                    } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Nessa situação, precisa entrar em contato com o gestor logistico do CD onde deseja fazer o carregamento ou descarga.
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>Em qual CD devo carregar ? </span>
                                    <PlusCircleIcon
                                    className={`${
                                        open ? "rotate-180 transform" : ""
                                    } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Processo pode ser alinhado junto ao gestor logistico do armazem de cada CD.
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="border-t-2 flex w-full justify-between rounded-lg px-4 py-2 text-left text-base font-medium text-slate-600  hover:bg-blue-50	 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>Motorista perdeu o agendamento, o que fazer? </span>
                                    <PlusCircleIcon
                                    className={`${
                                        open ? "rotate-180 transform" : ""
                                    } h-6 w-6 text-[#35175d]	`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="faqContent border-l border-x-slate-300 px-4 pt-4 pb-2 text-sm text-gray-500">
                                    Após perder o agendamento, o responsável devera criar um novo.
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
            </div>
        </>

    )

}

export default ViewFaq