import React, { useLayoutEffect, useState } from 'react'
import { Document, PDFViewer } from '@react-pdf/renderer'
import PDF from '@components/PDF'
import { RelatorioAgendamento } from '@modules/relatorio/interfaces/RelatorioAgendamento'
import sleep from '@utils/sleep'
import storage from '@utils/storage'
import Row from './components/PDF/Row'
import Header from './components/PDF/Header'
import HeaderRow from './components/PDF/HeaderRow'

const RelatorioAgendamentoPDF: React.FC = () => {

    const [data, setData] = useState<RelatorioAgendamento[] | null>(null)
    const [loading, setLoading] = useState<boolean>(true)

    const SHOW_DATA = !!data && !loading

    useLayoutEffect(() => {
        (async() => {
            setLoading(true)

            const localData = storage.getObject<RelatorioAgendamento[]>('pdfRelatorioAgendamentos')

            if(!!localData){
                await sleep(500)
                setData(localData)
            }

            setLoading(false)
        })()
    }, [setData, setLoading])

    return(

        <>
            {SHOW_DATA && (
                <PDFViewer style = {{ width: '100vw', height: '100vh' }}>
                    <Document>
                        <PDF.Page>
                            <Header />

                            <PDF.Section style = {{ marginTop: 12 }}>
                                <HeaderRow />

                                {data.map((item, index) => <Row key = {index} {...item} />)}
                            </PDF.Section>
                        </PDF.Page>
                    </Document>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioAgendamentoPDF