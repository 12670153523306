import React from 'react'

const Footer: React.FC = () => {

    return(

        <footer>
            <div id = "footerContainer"></div>
        </footer>

    )

}

export default Footer