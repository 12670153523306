
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Modulo } from "@modules/modulos/interfaces/Modulo"

interface State {
    modulos: Modulo[] | null
    currentModulo: Modulo | null
}

const initialState: State = {
    modulos: null,
    currentModulo: null
}

const ModuloSlice = createSlice({
    name: 'Modulo',
    initialState,
    reducers: {
        setModulos: (state, action: PayloadAction<Modulo[]>) => {
            state.modulos = action.payload
        },
        setCurrentModulo: (state, action: PayloadAction<Modulo | null>) => {
            state.currentModulo = action.payload
        },
    }
})

export const { setModulos, setCurrentModulo } = ModuloSlice.actions
export default ModuloSlice.reducer