import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GrupoAcesso } from "@modules/acesso/interfaces/GrupoAcesso"
import { AcessoGrupoPermissao } from "@modules/acesso/interfaces/AcessoGrupoPermissao"

interface State {
    acessoGrupos: GrupoAcesso[] | null
    currentAcessoGrupo: GrupoAcesso | null

    acessoGrupoPermissoes: AcessoGrupoPermissao[] | null
}

const initialState: State = {
    acessoGrupos: null,
    currentAcessoGrupo: null,
    acessoGrupoPermissoes: null
}

const acessoGrupoSlice = createSlice({
    name: 'acessoGrupoSlice',
    initialState,
    reducers: {
        setAcessoGrupos: (state, action: PayloadAction<GrupoAcesso[]>) => {
            state.acessoGrupos = action.payload
        },
        setCurrentAcessoGrupo: (state, action: PayloadAction<GrupoAcesso>) => {
            state.currentAcessoGrupo = action.payload
        },

        setAcessoGrupoPermissoes: (state, action: PayloadAction<AcessoGrupoPermissao[]>) => {
            state.acessoGrupoPermissoes = action.payload
        },
    }
})

export const { setAcessoGrupos, setCurrentAcessoGrupo, setAcessoGrupoPermissoes } = acessoGrupoSlice.actions
export default acessoGrupoSlice.reducer