import { RouteType } from "./interfaces"
import agendamentoRoutes from "@modules/agendamento/routes"
import authRoutes from "@modules/auth/routes"
import armazemRoutes from "@modules/armazem/routes"
import relatorioRoutes from "@modules/relatorio/routes"
import acessoRoutes from "@modules/acesso/routes"
import suporteRoutes from "@modules/suporte/routes"
import modulosRoutes from "@modules/modulos/routes"

const routes: RouteType[] = [
    ...authRoutes,
    ...agendamentoRoutes,
    ...armazemRoutes,
    ...relatorioRoutes,
    ...acessoRoutes,
    ...suporteRoutes,
    ...modulosRoutes,
]

const routesKeys: string[] = routes.reduce((acc: string[], route) => {
    acc.push(route.key)
    if (route.subRoutes) route.subRoutes.forEach(subRoute => acc.push(subRoute.key))
    return acc
}, [])

const notShowRoutesKeys: string[] = [
    ...authRoutes.map(i => i.key),
    ...suporteRoutes.map(i => i.key),
]

export { routes, routesKeys, notShowRoutesKeys }