import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getClientes, getTiposVeiculo } from '@modules/agendamento/controllers'
import FormCreate from './components/FormCreate'

const AgendamentoCadastro: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    
    useEffect(() => {
        getTiposVeiculo(dispatch, userData!)
        getClientes(dispatch, userData!)
    }, [dispatch, userData])

    return(

        <>
            <Section>
                <SectionBox title = "Novo agendamento" goBack>
                    <FormCreate />
                </SectionBox>
            </Section>
        </>

    )

}

export default AgendamentoCadastro