import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ArmazemBloqueio } from "@modules/armazem/interfaces/ArmazemBloqueio"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"

interface State {
    requestGetArmazemBloqueios: ResponseDefault<ArmazemBloqueio[]>
    requestSetArmazemBloqueio: ResponseDefault<any>
    requestSetArmazemDesbloqueio: ResponseDefault<any>
}

const initialState: State = {
    requestGetArmazemBloqueios: responseInitialValues,
    requestSetArmazemBloqueio: responseInitialValues,
    requestSetArmazemDesbloqueio: responseInitialValues,
}

const requestArmazemEditarSlice = createSlice({
    name: 'requestArmazemEditar',
    initialState,
    reducers: {
        setRequestGetArmazemBloqueiosData: (state: State, action: PayloadAction<ResponsePattern<ArmazemBloqueio[]>>) => {
            state.requestGetArmazemBloqueios.data = action.payload
            state.requestGetArmazemBloqueios.loading = false
            state.requestGetArmazemBloqueios.error = false
        },
        setRequestGetArmazemBloqueiosLoading: (state: State) => {
            state.requestGetArmazemBloqueios.loading = true
            state.requestGetArmazemBloqueios.error = false
        },
        setRequestGetArmazemBloqueiosError: (state: State) => {
            state.requestGetArmazemBloqueios.loading = false
            state.requestGetArmazemBloqueios.error = true
        },
        setRequestGetArmazemBloqueiosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetArmazemBloqueios.message = action.payload
        },
        resetRequestGetArmazemBloqueios: (state: State) => {
            state.requestGetArmazemBloqueios = {...responseInitialValues}
        },

        setRequestSetArmazemBloqueioData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSetArmazemBloqueio.data = action.payload
            state.requestSetArmazemBloqueio.loading = false
            state.requestSetArmazemBloqueio.error = false
        },
        setRequestSetArmazemBloqueioLoading: (state: State) => {
            state.requestSetArmazemBloqueio.loading = true
            state.requestSetArmazemBloqueio.error = false
        },
        setRequestSetArmazemBloqueioError: (state: State) => {
            state.requestSetArmazemBloqueio.loading = false
            state.requestSetArmazemBloqueio.error = true
        },
        setRequestSetArmazemBloqueioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSetArmazemBloqueio.message = action.payload
        },
        resetRequestSetArmazemBloqueio: (state: State) => {
            state.requestSetArmazemBloqueio = {...responseInitialValues}
        },

        setRequestSetArmazemDesbloqueioData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSetArmazemDesbloqueio.data = action.payload
            state.requestSetArmazemDesbloqueio.loading = false
            state.requestSetArmazemDesbloqueio.error = false
        },
        setRequestSetArmazemDesbloqueioLoading: (state: State) => {
            state.requestSetArmazemDesbloqueio.loading = true
            state.requestSetArmazemDesbloqueio.error = false
        },
        setRequestSetArmazemDesbloqueioError: (state: State) => {
            state.requestSetArmazemDesbloqueio.loading = false
            state.requestSetArmazemDesbloqueio.error = true
        },
        setRequestSetArmazemDesbloqueioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSetArmazemDesbloqueio.message = action.payload
        },
        resetRequestSetArmazemDesbloqueio: (state: State) => {
            state.requestSetArmazemDesbloqueio = {...responseInitialValues}
        },
    }
})

const actions = requestArmazemEditarSlice.actions

export const requestGetArmazemBloqueiosReducers = {
    data: actions.setRequestGetArmazemBloqueiosData,
    loading: actions.setRequestGetArmazemBloqueiosLoading,
    error: actions.setRequestGetArmazemBloqueiosError,
    message: actions.setRequestGetArmazemBloqueiosMessage,
    reset: actions.resetRequestGetArmazemBloqueios,
}

export const requestSetArmazemBloqueioReducers = {
    data: actions.setRequestSetArmazemBloqueioData,
    loading: actions.setRequestSetArmazemBloqueioLoading,
    error: actions.setRequestSetArmazemBloqueioError,
    message: actions.setRequestSetArmazemBloqueioMessage,
    reset: actions.resetRequestSetArmazemBloqueio,
}

export const requestSetArmazemDesbloqueioReducers = {
    data: actions.setRequestSetArmazemDesbloqueioData,
    loading: actions.setRequestSetArmazemDesbloqueioLoading,
    error: actions.setRequestSetArmazemDesbloqueioError,
    message: actions.setRequestSetArmazemDesbloqueioMessage,
    reset: actions.resetRequestSetArmazemDesbloqueio,
}

export default requestArmazemEditarSlice.reducer