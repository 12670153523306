import React from 'react'
import './styles.scss'
import FormLogin from './components/FormLogin'
import Logo from '@assets/images/logo-reserve.png'

const Login: React.FC = () => {

    return(

        <>
            <div id="formLoginContainer">
                <div className="loginContent">
                    <div className="loginGradient">
                        <img src={Logo} alt="" />
                    </div>
                </div>
                <div className="loginContent">
                    <FormLogin />
                </div>
            </div>
        </>

    )

}

export default Login