import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorMessage } from 'formik'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { SelectItem, SelectProps } from '@components/Select/types'
import createClassName from '@utils/createClassName'
import './styles.scss'

const SearchSelect: React.FC <SelectProps> = ({
    type = 'outline',
    defaultValue,
    label,
    placeholder,
    field,
    readonly = false,
    setValue,
    setFieldValue,
    onChange,
    error,
    value,
    status,
    items
}) => {

    const labelRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()
    const [data, setData] = useState<SelectItem[]>(items)
    const [search, setSearch] = useState<string>('')

    const selectClasses = createClassName(
        [`select-${type}`],
        [(status && type === 'outline') && `status ${status}`, open && 'open', error && 'error', readonly && 'disabled']
    )

    const selectLabel = useMemo(() => {
        return placeholder ?? label ?? 'Selecionar'
    }, [label, placeholder])
    
    const handleOpen = useCallback(() => {
        if(readonly) return
        else{
            if(open) setOpen(false)
            else{
                handleSearch(search)
                setOpen(true)
            }
        }
    }, [open, readonly])

    const handleClick = useCallback(({ value, label }: SelectItem) => {
        if(!!setValue) setValue(value)
        if(!!setFieldValue && field) setFieldValue(field, value)
        if(!!onChange) onChange()
        
        setSearch(label)

        setOpen(false)
    }, [setValue, setFieldValue, onChange, field])

    const handleSearch = (searchValue: string) => {
        if(searchValue === '') return setData(items)

        const filtered = items.filter(item => item.label.toLowerCase().includes(searchValue.toLowerCase()))
        setData(filtered ?? [])
    }

    useEffect(() => {
        if(defaultValue){
            if(!!setValue) setValue(defaultValue)
            if(!!setFieldValue && field) setFieldValue(field, defaultValue)
        }
    }, [setValue, setFieldValue, field, defaultValue])

    useEffect(() => {
        if(labelRef && labelRef.current) setPositions(labelRef.current.getBoundingClientRect())
    }, [data, open])

    useEffect(() => {
        setData(items)
    }, [items])

    return(

        <>
            <div className = "searchSelectWrapper">
                {!!label && <span className = "selectTitleLabel">{label}</span> }
                <div ref = {labelRef} className = {selectClasses} onClick = {handleOpen}>
                    <>
                        <input
                            type = "text"
                            // className = "selectLabel"
                            value = {search}
                            onChange = {({ target }) => {
                                setSearch(target.value)

                                handleSearch(target.value)
                            }}
                            placeholder = {selectLabel}
                            readOnly = {readonly}
                        />
                    </>
                </div>
                {(open && data.length > 0) && (
                    <ClickAwayListener onClickAway = {() => setOpen(false)}>
                        <ul 
                            className = {`selectItemsContainer scroll${open ? ' open' : ''}`} 
                            style = {{ width: positions?.width }}
                        >
                            {data.map((item, index) => 
                                <li key = {index} className = {`selectItem${item.value === value ? ' selected' : ''}`} onClick = {() => handleClick(item)}>{item.label}</li>
                            )}
                        </ul>
                    </ClickAwayListener>
                )}
                {(!readonly && !!field) && <ErrorMessage name = {field} className = "error" component = "h6" />}
            </div>
        </>

    )

}

export default SearchSelect