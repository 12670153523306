import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"

const PdfLine: React.FC = () => {

    const styles = StyleSheet.create({
        line: {
            width: '100%',
            borderTop: "1px solid black",
            marginVertical: 12,
        }
    })

    return(

        <View style = {styles.line} />

    )

}

export default PdfLine