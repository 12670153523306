import { RoutePageType } from "@routes/interfaces"
import AcessoGrupos from "./pages/AcessoGrupos"
import AcessoGrupo from "./pages/AcessoGrupo"
import AcessoUsuarios from "./pages/AcessoUsuarios"
import AcessoSolicitacaoLista from "./pages/AcessoSolicitacaoLista"

const acessoPages: RoutePageType[] = [
    {key: 'acesso-grupos', element: AcessoGrupos},
    {key: 'acesso-grupo', element: AcessoGrupo},
    {key: 'acesso-usuarios', element: AcessoUsuarios},
    {key: 'acessoSolicitacaoLista', element: AcessoSolicitacaoLista}
]

export default acessoPages