import React, { useContext } from 'react'
import TableContext from '@components/Table/context'
import TableRow from '../Row'

const TableHeader: React.FC = () => {

    const { columns } = useContext(TableContext)

    return(

        <thead>
            <TableRow>
                {columns.map((column, index) => <th key = {index}>{column}</th>)}
            </TableRow>
        </thead>

    )

}

export default TableHeader