import React, { PropsWithChildren } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

const Table: React.FC <PropsWithChildren> = ({ children }) => {

    return(

        <OverlayScrollbarsComponent
            element = "div"
            className = "tableContainer"
            defer
        >
            <table className = "tableList">{children}</table>
        </OverlayScrollbarsComponent>

    )

}

export default Table