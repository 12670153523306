import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { mdiEye, mdiPencil } from '@mdi/js'
import { createTableConfig } from '@components/Table/scripts/tableConfig'
import { TableColumn, TableOption, TablePaginator, TableRow } from '@components/Table'
import { setCurrentAgendamentoLista } from '@modules/agendamento/reducers/agendamentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { setOpenModalAgendamento } from '../../reducers/agendamentoListaReducer'

const TableAgendamentos: React.FC = () => {

    const dispatch = useAppDispatch()
    const { agendamentosLista } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentosLista } = useAppSelector(s => s.requestAgendamento)

    const [tableConfig] = React.useState(createTableConfig(
        'agendamentos',
        ['Código', 'Armazém', 'Peso', 'Motorista', 'Categoria', 'Ativo', 'Placa', 'Tipo', 'Usuário', 'Transportadora', 'Cadastro', 'Status', 'Opções']
    ))

    const { guid } = useParams()

    const CR = conditionaRender(requestGetAgendamentosLista, agendamentosLista, true)

    useEffect(() => {
        if(!!agendamentosLista && !!guid){
            const current = agendamentosLista.find(f => f.guid === guid)
            if(!!current){
                dispatch(setCurrentAgendamentoLista(current))
                dispatch(setOpenModalAgendamento(true))
            }
        }
    }, [dispatch, agendamentosLista, guid])

    return(

        <>
            <TablePaginator
                data = {agendamentosLista}
                config = {tableConfig}
                conditionalRender = {CR}
                renderItem = {(item, index) => (
                    <TableRow key = {index}>
                        <TableColumn>{item.id}</TableColumn>
                        <TableColumn>{item.descricaoArmazem}</TableColumn>
                        <TableColumn>{item.peso}</TableColumn>
                        <TableColumn>{item.nomeMotorista}</TableColumn>
                        <TableColumn>{item.tipoMotorista}</TableColumn>
                        <TableColumn>{item.flagMotoristaAtivo}</TableColumn>
                        <TableColumn>{item.placa}</TableColumn>
                        <TableColumn>{item.tipo === 'C' ? 'Carga' : 'Descarga'}</TableColumn>
                        <TableColumn>{item.nomeUsuarioCadastro}</TableColumn>
                        <TableColumn>{item.nomeTransportadoraCadastro}</TableColumn>
                        <TableColumn>{item.dtCadastroFormatada}</TableColumn>
                        <TableColumn>{item.descricaoStatus}</TableColumn>
                        <TableColumn>
                            <TableOption
                                icon = {mdiPencil}
                                link = {`/agendamento/editar/${item.guid}/${item.id}`}
                                onClick = {() => {
                                    dispatch(setCurrentAgendamentoLista(item))
                                }}
                            />
                            <TableOption
                                icon = {mdiEye}
                                onClick = {() => {
                                    dispatch(setCurrentAgendamentoLista(item))
                                    dispatch(setOpenModalAgendamento(true))
                                }}
                            />
                        </TableColumn>
                    </TableRow>
                )}
            />
        </>

    )

}

export default TableAgendamentos