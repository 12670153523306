import { RouteType } from "@routes/interfaces"

const suporteRoutes: RouteType[] = [
    {
        key: 'suporte',
        path: 'suporte',
        title: 'Suporte',
        userVerification: true,
        subRoutes: [
            {key: 'suporteFaq', path: 'faq', title: 'FAQ', userVerification: true}
        ]
    }
]

export default suporteRoutes