import { RoutePageType } from "./interfaces"
import agendamentoPages from "@modules/agendamento/pages"
import authPages from "@modules/auth/pages"
import armazemPages from "@modules/armazem/pages"
import relatorioPages from "@modules/relatorio/pages"
import acessoPages from "@modules/acesso/pages"
import suportePages from "@modules/suporte/pages"
import modulosPages from "@modules/modulos/pages"

const routesPages: RoutePageType[] = [
    ...authPages,
    ...agendamentoPages,
    ...armazemPages,
    ...relatorioPages,
    ...acessoPages,
    ...suportePages,
    ...modulosPages,
]

export { routesPages }