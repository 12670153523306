import { BloqueioValues } from "@modules/armazem/controllers/types"
import { ArmazemBloqueio } from "@modules/armazem/interfaces/ArmazemBloqueio"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    armazemBloqueios: ArmazemBloqueio[] | null
    selectedArmazens: BloqueioValues[]

    bloqueioValues: BloqueioValues | null

    filterData: string

    openModalRemove: boolean
    openModalBloquear: boolean
    openModalDesbloquear: boolean
}

const initialState: State = {
    armazemBloqueios: null,
    selectedArmazens: [],
    bloqueioValues: null,
    filterData: '',
    openModalRemove: false,
    openModalBloquear: false,
    openModalDesbloquear: false,
}

const armazemEditarSlice = createSlice({
    name: 'armazemEditar',
    initialState,
    reducers: {
        setArmazemBloqueios: (state, action: PayloadAction<ArmazemBloqueio[] | null>) => {
            state.armazemBloqueios = action.payload
        },
        setSelectedArmazens: (state, action: PayloadAction<BloqueioValues>) => {
            const alreadySelected = state.selectedArmazens.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${action.payload.horario}d${action.payload.nroDoca}`)
            
            if(!!alreadySelected){
                state.selectedArmazens = state.selectedArmazens.filter(({ horario, nroDoca }) => `h${horario}d${nroDoca}` !== `h${action.payload.horario}d${action.payload.nroDoca}`)
            }else{
                state.selectedArmazens.push(action.payload)
            }
        },
        resetSelectedArmazens: (state) => {
            state.selectedArmazens = []
        },

        setBloqueioValues: (state, action: PayloadAction<BloqueioValues | null>) => {
            state.bloqueioValues = action.payload
        },

        setFilterData: (state, action: PayloadAction<string>) => {
            state.filterData = action.payload
        },

        setOpenModalRemove: (state, action: PayloadAction<boolean>) => {
            state.openModalRemove = action.payload
        },
        setOpenModalBloquear: (state, action: PayloadAction<boolean>) => {
            state.openModalBloquear = action.payload
        },
        setOpenModalDesbloquear: (state, action: PayloadAction<boolean>) => {
            state.openModalDesbloquear = action.payload
        },
    }
})

export const {
    setArmazemBloqueios, setFilterData, setBloqueioValues,
    setSelectedArmazens, resetSelectedArmazens,
    setOpenModalRemove, setOpenModalBloquear, setOpenModalDesbloquear
} = armazemEditarSlice.actions
export default armazemEditarSlice.reducer