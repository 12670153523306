import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import Painel from './components/Painel'
import FormFilter from './components/FormFilter'
import ModalDesbloqueio from './components/ModalDesbloqueio'
import { useAppDispatch } from '@redux/hooks'
import { requestGetAgendamentosBloqueadosReducers } from '@modules/agendamento/reducers/requestAgendamentoReducer'

const AgendamentoConsultaBloqueados: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(requestGetAgendamentosBloqueadosReducers.reset())
        }
    }, [dispatch])

    return(

        <>
             <Section>
                <SectionBox title = "Agendamentos bloqueados">
                    <FormFilter />
                    <Painel />
                </SectionBox>
            </Section>
            <ModalDesbloqueio />
        </>

    )

}

export default AgendamentoConsultaBloqueados