import React, { useLayoutEffect, useState } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import PDF from '@components/PDF'
import storage from '@utils/storage'
import { formatDate } from '@utils/format'

const Header: React.FC = () => {

    const [filterData, setFilterData] = useState<any | null>(null)

    useLayoutEffect(() => {
        const localFilterData = storage.getObject<any>('pdfRelatorioAgendamentosFilter')

        if(!!localFilterData){
            setFilterData(localFilterData)
        }
    }, [setFilterData])

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    })

    return(

        <>
            {!!filterData && (
                <PDF.Header>
                    <View style = {styles.container}>
                        <Text>Planejamento e Controle Operacional</Text>
                        <Text style = {{ fontSize: 10 }}>{`${formatDate(filterData.dtInicio).split(' ')[0]} - ${formatDate(filterData.dtFim).split(' ')[0]}`}</Text>
                    </View>
                </PDF.Header>
            )}
        </>

    )

}

export default Header