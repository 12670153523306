import { DispatchType } from '@redux/types'
import request from "@utils/request"
import { handleResponse, initRequest } from '@utils/response'
import { ResponsePattern } from "@utils/response/types"
import { AcessoSolicitacao } from "../interfaces/AcessoSolicitacao"
import { setAcessoSolicitacoes } from "../reducers/solicitacao/acessoSolicitacaoReducer"
import { requestDeleteAcessoSolicitacao, requestGetAcessoSolicitacoes, requestValidarAcessoSolicitacao } from "../reducers/solicitacao/requestAcessoSolicitacaoReducer"

export async function getAcessoSolicitacoes(dispatch: DispatchType){
    initRequest(dispatch, requestGetAcessoSolicitacoes)

    const endpoint = `http://3.228.242.23:9001/solicitacao-acesso`
    const response = await request.get<ResponsePattern<AcessoSolicitacao[]>>({ endpoint })

    handleResponse('getAcessoSolicitacoes', dispatch, response, requestGetAcessoSolicitacoes)
    .then(data => {
        dispatch(setAcessoSolicitacoes(data))
    })
}

export async function deleteAcessoSolicitacao(dispatch: DispatchType, id: number){
    initRequest(dispatch, requestDeleteAcessoSolicitacao)

    const endpoint = `http://3.228.242.23:9001/solicitacao-acesso/excluir`
    const body = {
        id,
        dtExclusao: new Date().toISOString(),
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteAcessoSolicitacao', dispatch, response, requestDeleteAcessoSolicitacao)
    .then(() => {
        getAcessoSolicitacoes(dispatch)
    })
}

export async function validarAcessoSolicitacao(dispatch: DispatchType, idSolicitacaoAcesso: number, flagValidar: boolean, setOpenModal: Function, setRedirect: Function){
    initRequest(dispatch, requestValidarAcessoSolicitacao)

    const endpoint = `http://3.228.242.23:9001/solicitacao-acesso/validar`
    const body = {
        idSolicitacaoAcesso,
        flagValidar,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('validarAcessoSolicitacao', dispatch, response, requestValidarAcessoSolicitacao)
    .then(() => {
        dispatch(setOpenModal(false))
        getAcessoSolicitacoes(dispatch)
        setRedirect(true)
    })
}