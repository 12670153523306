import * as yup from 'yup'

export const formValues = {
    nroNota: '',
    nroSerie: '',
    arquivo: '',
    extensao: ''
}

export const formSchema = yup.object().shape({
    nroNota: yup.string().required('Campo obrigatório'),
    nroSerie: yup.string().required('Campo obrigatório'),
    arquivo: yup.mixed().required(),
    extensao: yup.string().required('Campo obrigatório'),
})