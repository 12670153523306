import React from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import SectionBoxData from '@components/Page/Section/data'
import FormCreate from './components/FormCreate'

const ArmazemCadastro: React.FC = () => {

    return(

        <>
            <Section>
                <SectionBox
                    title = "Criar armazém"
                    goBack
                >
                    <SectionBoxData>
                        <FormCreate />
                    </SectionBoxData>
                </SectionBox>
            </Section>
        </>

    )

}

export default ArmazemCadastro