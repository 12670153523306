import { configureStore } from '@reduxjs/toolkit'
import requestAgendamentoReducer from '@modules/agendamento/reducers/requestAgendamentoReducer'
import alertReducer from '@modules/app/reducers/alertReducer'
import appReducer from '@modules/app/reducers/appReducer'
import authReducer from '@modules/auth/reducers/authReducer'
import requestAuthReducer from '@modules/auth/reducers/requestAuthReducer'
import themeReducer from '@modules/theme/reducers/themeReducer'
import agendamentoReducer from '@modules/agendamento/reducers/agendamentoReducer'
import agendamentoConsultaReducer from '@modules/agendamento/pages/AgendamentoConsulta/reducers/agendamentoConsultaReducer'
import agendamentoListaReducer from '@modules/agendamento/pages/AgendamentoLista/reducers/agendamentoListaReducer'
import armazemReducer from '@modules/armazem/reducers/armazemReducer'
import requestArmazemReducer from '@modules/armazem/reducers/requestArmazemReducer'
import armazenEditarReducer from '@modules/armazem/pages/ArmazemEditar/reducers/armazenEditarReducer'
import requestArmazemEditarReducer from '@modules/armazem/pages/ArmazemEditar/reducers/requestArmazemEditarReducer'
import agendamentoCadastroHorarioReducer from '@modules/agendamento/pages/AgendamentoCadastroHorario/reducers/agendamentoCadastroHorarioReducer'
import agendamentoConsultaBloqueadosReducer from '@modules/agendamento/pages/AgendamentoConsultaBloqueados/reducers/agendamentoConsultaBloqueadosReducer'
import relatorioReducer from '@modules/relatorio/reducers/relatorioReducer'
import requestRelatorioReducer from '@modules/relatorio/reducers/requestRelatorioReducer'
import acessoReducer from '@modules/acesso/reducers/acessoReducer'
import requestAcessoReducer from '@modules/acesso/reducers/requestAcessoReducer'
import acessoSolicitacoesListaReducer from '@modules/acesso/pages/AcessoSolicitacaoLista/reducers/acessoSolicitacoesListaReducer'
import agendamentoEditarReducer from '@modules/agendamento/pages/AgendamentoEditar/reducers/agendamentoEditarReducer'
import moduloReducer from '@modules/modulos/reducers/modulo/moduloReducer'
import requestModuloReducer from '@modules/modulos/reducers/modulo/requestModuloReducer'
import menuReducer from '@modules/modulos/reducers/menu/menuReducer'
import requestMenuReducer from '@modules/modulos/reducers/menu/requestMenuReducer'
import acessoGrupoReducer from '@modules/acesso/reducers/grupo/acessoGrupoReducer'
import requestAcessoGrupoReducer from '@modules/acesso/reducers/grupo/requestAcessoGrupoReducer'
import usuarioReducer from '@modules/acesso/reducers/usuario/usuarioReducer'
import requestUsuarioReducer from '@modules/acesso/reducers/usuario/requestUsuarioReducer'
import acessoModuloReducer from '@modules/acesso/reducers/modulo/acessoModuloReducer'
import requestAcessoModuloReducer from '@modules/acesso/reducers/modulo/requestAcessoModuloReducer'
import acessoSolicitacaoReducer from '@modules/acesso/reducers/solicitacao/acessoSolicitacaoReducer'
import requestAcessoSolicitacaoReducer from '@modules/acesso/reducers/solicitacao/requestAcessoSolicitacaoReducer'

const store = configureStore({
    reducer: {
        app: appReducer,

        auth: authReducer,
        requestAuth: requestAuthReducer,
        
        alert: alertReducer,
        theme: themeReducer,

        agendamento: agendamentoReducer,
        requestAgendamento: requestAgendamentoReducer,

        agendamentoConsulta: agendamentoConsultaReducer,

        agendamentoLista: agendamentoListaReducer,

        agendamentoCadastroHorario: agendamentoCadastroHorarioReducer,

        agendamentoConsultaBloqueados: agendamentoConsultaBloqueadosReducer,

        agendamentoEditar: agendamentoEditarReducer,

        armazem: armazemReducer,
        requestArmazem: requestArmazemReducer,

        armazemEditar: armazenEditarReducer,
        requestArmazemEditar: requestArmazemEditarReducer,

        relatorio: relatorioReducer,
        requestRelatorio: requestRelatorioReducer,

        acesso: acessoReducer,
        requestAcesso: requestAcessoReducer,

        acessoSolicitacoesLista: acessoSolicitacoesListaReducer,

        modulo: moduloReducer,
        requestModulo: requestModuloReducer,

        menu: menuReducer,
        requestMenu: requestMenuReducer,

        acessoGrupo: acessoGrupoReducer,
        requestAcessGrupo: requestAcessoGrupoReducer,

        acessoUsuario: usuarioReducer,
        requestAcessoUsuario: requestUsuarioReducer,

        acessoModulo: acessoModuloReducer,
        requestAcessoModulo: requestAcessoModuloReducer,

        acessoSolicitacao: acessoSolicitacaoReducer,
        requestAcessoSolicitacao: requestAcessoSolicitacaoReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store