import { RelatorioAgendamento } from "@modules/relatorio/interfaces/RelatorioAgendamento"
import saveExcel from "@utils/saveExcel"

export default function createExcel(relatorioAgendamentos: RelatorioAgendamento[]){
    const header = ['Código', 'Data', 'Janela', 'Peso', 'Tipo', 'Placa', 'Tipo de veículo', 'Status', 'Cliente', 'Usuário', 'Transportadora', 'Motorista', 'Categoria', 'Ativo']
    const values = relatorioAgendamentos.map((item) => {
        return {
            'Código': item.id,
            'Data': new Date(item.data).toLocaleString(),
            'Janela': item.nrDoca,
            'Peso': Number(item.peso),
            'Tipo': item.tipo === 'C' ? 'Carga' : 'Descarga',
            'Placa': item.placa,
            'Tipo de veículo': item.tipoVeiculo,
            'Status': item.descricaoStatus,
            'Cliente': item.nmCliente,
            'Usuário': item.nomeUsuarioCadastro,
            'Transportadora': item.nomeTransportadoraCadastro,
            'Motorista': item.nomeMotorista,
            'Categoria': item.tipoMotorista,
            'Ativo': item.flagMotoristaAtivo,
        }
    })

    saveExcel('relatorio-agendamento.xlsx', header, values)
}