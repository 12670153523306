import React from 'react'
import { mdiCancel, mdiDelete } from '@mdi/js'
import Modal from '@components/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setOpenModalRemove } from '../../reducers/armazenEditarReducer'
import ButtonGroup from '@components/Button/Group'
import Button from '@components/Button'
import { removeArmazem } from '@modules/armazem/controllers'
import { ModalRemoveProps } from './types'

const ModalRemove: React.FC <ModalRemoveProps> = ({ redirect }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazem } = useAppSelector(s => s.armazem)
    const { requestRemoveArmazem } = useAppSelector(s => s.requestArmazem)
    const { openModalRemove } = useAppSelector(s => s.armazemEditar)

    return(

        <Modal title = "Remover armazém" width = {400} open = {openModalRemove} setOpen = {setOpenModalRemove}>
            <span className = "modalBoxMessage">{`Deseja remover ${armazem?.descricao}?`}</span>
            <ButtonGroup>
                <Button
                    icon = {mdiCancel}
                    label = "Cancelar"
                    onClick = {() => dispatch(setOpenModalRemove(false))}
                />
                <Button
                    icon = {mdiDelete}
                    loading = {requestRemoveArmazem.loading}
                    label = "Remover"
                    status = "error"
                    onClick = {() => removeArmazem(dispatch, armazem!.guid, redirect, userData!)}
                />
            </ButtonGroup>
        </Modal>

    )

}

export default ModalRemove