import React, { PropsWithChildren } from 'react'
import { View, StyleSheet, Image } from "@react-pdf/renderer"
import Logo from '@assets/images/logo-comando.png'

const PdfHeader: React.FC <PropsWithChildren> = ({ children }) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        },
        image: {
            width: '100px',
            height: 'auto',
        },
    })

    return(

        <View style = {styles.row}>
            <Image source = {Logo} style = {styles.image} />
            {children}
        </View>

    )

}

export default PdfHeader