import React from 'react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { FormSearchNotaFiscalCnpjProps } from './types'
import { useAppSelector } from '@redux/hooks'

const FormSearchNotaFiscalCnpj: React.FC <FormSearchNotaFiscalCnpjProps> = ({ handleSearch, handleReset }) => {

    const { userData } = useAppSelector(s => s.auth)

    return(

        <Formik
            initialValues = {{
                valorBusca: userData?.nroDocumento ?? ''
            }}
            onSubmit = {v => {
                handleSearch(v.valorBusca)
            }}
        >
            {() => (
                <FormikForm>
                    <Form.Container padding = {false}>
                        <Form.Row columns = {2} grid = "auto auto">
                            <Form.Group
                                label = "Pesquisar"
                                inputName = "valorBusca"
                                inputID = "valorBusca"
                            />

                            <ButtonGroup>
                                <Button
                                    icon = {mdiMagnify}
                                    status = "info"
                                    type = "submit"
                                />
                                <Button
                                    icon = {mdiClose}
                                    type = "reset"
                                    status = "error"
                                    onClick = {handleReset}
                                />
                            </ButtonGroup>
                        </Form.Row>
                    </Form.Container>
                </FormikForm>
            )}
        </Formik>

    )

}

export default FormSearchNotaFiscalCnpj