import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    openModalSolicitacao: boolean
    openModalSaveSolicitacao: boolean
}

const initialState: State = {
    openModalSolicitacao: false,
    openModalSaveSolicitacao: false,
}

const acessoSolicitacoesListaSlice = createSlice({
    name: 'acessoSolicitacoesLista',
    initialState,
    reducers: {
        setOpenModalSolicitacao: (state, action: PayloadAction<boolean>) => {
            state.openModalSolicitacao = action.payload
        },
        setOpenModalSaveSolicitacao: (state, action: PayloadAction<boolean>) => {
            state.openModalSaveSolicitacao = action.payload
        },
    }
})

export const { setOpenModalSolicitacao, setOpenModalSaveSolicitacao } = acessoSolicitacoesListaSlice.actions
export default acessoSolicitacoesListaSlice.reducer