import ENV from "@env/index"
import { UserData } from "@modules/auth/interfaces/UserData"
import getNroDocumento from "@modules/auth/scripts/getNroDocumento"
import { DispatchType } from "@redux/types"
import { jsonToParams } from "@utils/jsonToParams"
import request from "@utils/request"
import { handleResponse } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { formValues } from "../pages/RelatorioAgendamento/components/FormFilter/config"
import { setRelatorioAgendamentos } from "../reducers/relatorioReducer"
import { requestGetRelatorioAgendamentosReducers } from "../reducers/requestRelatorioReducer"

export async function getRelatorioAgendamentos(dispatch: DispatchType, { idArmazem, dtInicio, dtFim }: typeof formValues, userData: UserData){
    dispatch(requestGetRelatorioAgendamentosReducers.reset())
    dispatch(requestGetRelatorioAgendamentosReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoBI`
    const params = `?${jsonToParams({...getNroDocumento(userData), idArmazem})}&dtInicio=${dtInicio}%2000:00:00&dtFim=${dtFim}%2000:00:00`
    const response = await request.get<ResponsePattern<any[]>>({ endpoint, params })

    handleResponse('getRelatorioAgendamentos', dispatch, response, requestGetRelatorioAgendamentosReducers)
    .then(data => dispatch(setRelatorioAgendamentos(data)))
}