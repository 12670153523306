import { AcessoGrupoPermissao } from "@modules/acesso/interfaces/AcessoGrupoPermissao"
import { GrupoAcesso } from "@modules/acesso/interfaces/GrupoAcesso"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "../../../../utils/response"
import { ResponseDefault, ResponsePattern } from "../../../../utils/response/types"

interface State {
    requestGetAcessoGrupos: ResponseDefault<GrupoAcesso[]>
    requestGetAcessoGrupoPermissoes: ResponseDefault<AcessoGrupoPermissao[]>
    requestRemoveAcessoGrupo: ResponseDefault<any>
    requestAddAcessoGrupo: ResponseDefault<any>
    requestUpdateAcessoGrupo: ResponseDefault<any>
    requestSaveAcessoGrupo: ResponseDefault<any>
    requestSaveAcessoGrupoHome: ResponseDefault<any>
}

const initialState: State = {
    requestGetAcessoGrupos: responseInitialValues,
    requestGetAcessoGrupoPermissoes: responseInitialValues,
    requestRemoveAcessoGrupo: responseInitialValues,
    requestAddAcessoGrupo: responseInitialValues,
    requestUpdateAcessoGrupo: responseInitialValues,
    requestSaveAcessoGrupo: responseInitialValues,
    requestSaveAcessoGrupoHome: responseInitialValues
}

const requestAcessoGrupoSlice = createSlice({
    name: 'requestAcesso',
    initialState,
    reducers: {
        setRequestGetAcessoGruposData: (state, action: PayloadAction<ResponsePattern<GrupoAcesso[]>>) => {
            state.requestGetAcessoGrupos.data = action.payload
            state.requestGetAcessoGrupos.loading = false
            state.requestGetAcessoGrupos.error = false
        },
        setRequestGetAcessoGruposLoading: (state) => {
            state.requestGetAcessoGrupos.loading = true
            state.requestGetAcessoGrupos.error = false
        },
        setRequestGetAcessoGruposError: (state) => {
            state.requestGetAcessoGrupos.loading = false
            state.requestGetAcessoGrupos.error = true
        },
        setRequestGetAcessoGruposMessage: (state, action: PayloadAction<string>) => {
            state.requestGetAcessoGrupos.message = action.payload
        },
        resetRequestGetAcessoGrupos: (state) => {
            state.requestGetAcessoGrupos = {...responseInitialValues}
        },

        setRequestGetAcessoGrupoPermissoesData: (state, action: PayloadAction<ResponsePattern<AcessoGrupoPermissao[]>>) => {
            state.requestGetAcessoGrupoPermissoes.data = action.payload
            state.requestGetAcessoGrupoPermissoes.loading = false
            state.requestGetAcessoGrupoPermissoes.error = false
        },
        setRequestGetAcessoGrupoPermissoesLoading: (state) => {
            state.requestGetAcessoGrupoPermissoes.loading = true
            state.requestGetAcessoGrupoPermissoes.error = false
        },
        setRequestGetAcessoGrupoPermissoesError: (state) => {
            state.requestGetAcessoGrupoPermissoes.loading = false
            state.requestGetAcessoGrupoPermissoes.error = true
        },
        setRequestGetAcessoGrupoPermissoesMessage: (state, action: PayloadAction<string>) => {
            state.requestGetAcessoGrupoPermissoes.message = action.payload
        },
        resetRequestGetAcessoGrupoPermissoes: (state) => {
            state.requestGetAcessoGrupoPermissoes = {...responseInitialValues}
        },

        setRequestRemoveAcessoGrupoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveAcessoGrupo.data = action.payload
            state.requestRemoveAcessoGrupo.loading = false
            state.requestRemoveAcessoGrupo.error = false
        },
        setRequestRemoveAcessoGrupoLoading: (state) => {
            state.requestRemoveAcessoGrupo.loading = true
            state.requestRemoveAcessoGrupo.error = false
        },
        setRequestRemoveAcessoGrupoError: (state) => {
            state.requestRemoveAcessoGrupo.loading = false
            state.requestRemoveAcessoGrupo.error = true
        },
        setRequestRemoveAcessoGrupoMessage: (state, action: PayloadAction<string>) => {
            state.requestRemoveAcessoGrupo.message = action.payload
        },
        resetRequestRemoveAcessoGrupo: (state) => {
            state.requestRemoveAcessoGrupo = {...responseInitialValues}
        },

        setRequestAddAcessoGrupoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestAddAcessoGrupo.data = action.payload
            state.requestAddAcessoGrupo.loading = false
            state.requestAddAcessoGrupo.error = false
        },
        setRequestAddAcessoGrupoLoading: (state) => {
            state.requestAddAcessoGrupo.loading = true
            state.requestAddAcessoGrupo.error = false
        },
        setRequestAddAcessoGrupoError: (state) => {
            state.requestAddAcessoGrupo.loading = false
            state.requestAddAcessoGrupo.error = true
        },
        setRequestAddAcessoGrupoMessage: (state, action: PayloadAction<string>) => {
            state.requestAddAcessoGrupo.message = action.payload
        },
        resetRequestAddAcessoGrupo: (state) => {
            state.requestAddAcessoGrupo = {...responseInitialValues}
        },

        setRequestUpdateAcessoGrupoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateAcessoGrupo.data = action.payload
            state.requestUpdateAcessoGrupo.loading = false
            state.requestUpdateAcessoGrupo.error = false
        },
        setRequestUpdateAcessoGrupoLoading: (state) => {
            state.requestUpdateAcessoGrupo.loading = true
            state.requestUpdateAcessoGrupo.error = false
        },
        setRequestUpdateAcessoGrupoError: (state) => {
            state.requestUpdateAcessoGrupo.loading = false
            state.requestUpdateAcessoGrupo.error = true
        },
        setRequestUpdateAcessoGrupoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateAcessoGrupo.message = action.payload
        },
        resetRequestUpdateAcessoGrupo: (state) => {
            state.requestUpdateAcessoGrupo = {...responseInitialValues}
        },

        setRequestSaveAcessoGrupoData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveAcessoGrupo.data = action.payload
            state.requestSaveAcessoGrupo.loading = false
            state.requestSaveAcessoGrupo.error = false
        },
        setRequestSaveAcessoGrupoLoading: (state) => {
            state.requestSaveAcessoGrupo.loading = true
            state.requestSaveAcessoGrupo.error = false
        },
        setRequestSaveAcessoGrupoError: (state) => {
            state.requestSaveAcessoGrupo.loading = false
            state.requestSaveAcessoGrupo.error = true
        },
        setRequestSaveAcessoGrupoMessage: (state, action: PayloadAction<string>) => {
            state.requestSaveAcessoGrupo.message = action.payload
        },
        resetRequestSaveAcessoGrupo: (state) => {
            state.requestSaveAcessoGrupo = {...responseInitialValues}
        },

        setRequestSaveAcessoGrupoHomeData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveAcessoGrupoHome.data = action.payload
            state.requestSaveAcessoGrupoHome.loading = false
            state.requestSaveAcessoGrupoHome.error = false
        },
        setRequestSaveAcessoGrupoHomeLoading: (state) => {
            state.requestSaveAcessoGrupoHome.loading = true
            state.requestSaveAcessoGrupoHome.error = false
        },
        setRequestSaveAcessoGrupoHomeError: (state) => {
            state.requestSaveAcessoGrupoHome.loading = false
            state.requestSaveAcessoGrupoHome.error = true
        },
        setRequestSaveAcessoGrupoHomeMessage: (state, action: PayloadAction<string>) => {
            state.requestSaveAcessoGrupoHome.message = action.payload
        },
        resetRequestSaveAcessoGrupoHome: (state) => {
            state.requestSaveAcessoGrupoHome = {...responseInitialValues}
        },
    }
})

const { actions } = requestAcessoGrupoSlice

export const requestGetAcessoGrupos = {
    data: actions.setRequestGetAcessoGruposData,
    loading: actions.setRequestGetAcessoGruposLoading,
    error: actions.setRequestGetAcessoGruposError,
    message: actions.setRequestGetAcessoGruposMessage,
    reset: actions.resetRequestGetAcessoGrupos,
}

export const requestGetAcessoGrupoPermissoes = {
    data: actions.setRequestGetAcessoGrupoPermissoesData,
    loading: actions.setRequestGetAcessoGrupoPermissoesLoading,
    error: actions.setRequestGetAcessoGrupoPermissoesError,
    message: actions.setRequestGetAcessoGrupoPermissoesMessage,
    reset: actions.resetRequestGetAcessoGrupoPermissoes,
}

export const requestAddAcessoGrupo = {
    data: actions.setRequestAddAcessoGrupoData,
    loading: actions.setRequestAddAcessoGrupoLoading,
    error: actions.setRequestAddAcessoGrupoError,
    message: actions.setRequestAddAcessoGrupoMessage,
    reset: actions.resetRequestAddAcessoGrupo,
}

export const requestUpdateAcessoGrupo = {
    data: actions.setRequestUpdateAcessoGrupoData,
    loading: actions.setRequestUpdateAcessoGrupoLoading,
    error: actions.setRequestUpdateAcessoGrupoError,
    message: actions.setRequestUpdateAcessoGrupoMessage,
    reset: actions.resetRequestUpdateAcessoGrupo,
}

export const requestRemoveAcessoGrupo = {
    data: actions.setRequestRemoveAcessoGrupoData,
    loading: actions.setRequestRemoveAcessoGrupoLoading,
    error: actions.setRequestRemoveAcessoGrupoError,
    message: actions.setRequestRemoveAcessoGrupoMessage,
    reset: actions.resetRequestRemoveAcessoGrupo,
}

export const requestSaveAcessoGrupo = {
    data: actions.setRequestSaveAcessoGrupoData,
    loading: actions.setRequestSaveAcessoGrupoLoading,
    error: actions.setRequestSaveAcessoGrupoError,
    message: actions.setRequestSaveAcessoGrupoMessage,
    reset: actions.resetRequestSaveAcessoGrupo,
}

export const requestSaveAcessoGrupoHome = {
    data: actions.setRequestSaveAcessoGrupoHomeData,
    loading: actions.setRequestSaveAcessoGrupoHomeLoading,
    error: actions.setRequestSaveAcessoGrupoHomeError,
    message: actions.setRequestSaveAcessoGrupoHomeMessage,
    reset: actions.resetRequestSaveAcessoGrupoHome,
}

export default requestAcessoGrupoSlice.reducer