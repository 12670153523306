import { SelectItem } from "@components/Select/types"

const selectStatus: SelectItem[] = [
    { label: 'Agendado', value: '1' },
    { label: 'Checkin', value: '2' },
    { label: 'Fila Entrada', value: '3' },
    { label: 'Balança', value: '4' },
    { label: 'Entrou', value: '5' },
    { label: 'In. Operação', value: '6' },
    { label: 'Fn. Operação', value: '7' },
    { label: 'Saiu', value: '8' },
    { label: 'Faltou', value: '9' },
]

export default selectStatus