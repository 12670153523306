import * as yup from 'yup'

export const formValues = {
    descricao: '',
    endereco: '',
    qtdeDocas: '',
    intervalo: '',
    listaEmail: '',

    qtdeLimiteAgendamentosDia: '',

    flagRegraAgendamento: 'N',

    atendimentoManhaEntrada: '',
    atendimentoManhaSaida: '',
    atendimentoTardeEntrada: '',
    atendimentoTardeSaida: '',
}

export const formSchema = yup.object().shape({
    descricao: yup.string().required('Campo obrigatório'),
    endereco: yup.string().required('Campo obrigatório'),
    qtdeDocas: yup.string().required('Campo obrigatório'),
    intervalo: yup.string().required('Campo obrigatório'),
    listaEmail: yup.string().required('Campo obrigatório'),

    flagRegraAgendamento: yup.string().required('Campo obrigatório'),
    qtdeLimiteAgendamentosDia: yup.string().required('Campo obrigatório'),

    atendimentoManhaEntrada: yup.string().required('Campo obrigatório'),
    atendimentoManhaSaida: yup.string().required('Campo obrigatório'),
    atendimentoTardeEntrada: yup.string().required('Campo obrigatório'),
    atendimentoTardeSaida: yup.string().required('Campo obrigatório'),
})

const formCreate = {
    values: formValues,
    schema: formSchema,
}

export default formCreate