import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    openModalAgendamento: boolean
}

const initialState: State = {
    openModalAgendamento: false,
}

const agendamentoConsultaSlice = createSlice({
    name: 'agendamentoConsulta',
    initialState,
    reducers: {
        setOpenModalAgendamento: (state, action: PayloadAction<boolean>) => {
            state.openModalAgendamento = action.payload
        },
    }
})

export const { setOpenModalAgendamento } = agendamentoConsultaSlice.actions
export default agendamentoConsultaSlice.reducer