import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { TextContainerProps } from './types'

const PdfTextContainer: React.FC <TextContainerProps> = ({ title, text, titleWidth }) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: 2,
        },
        title: {
            width: titleWidth,
            fontSize: 6,
            fontfamily: 'Arial',
        },
        text: {
            fontSize: 8,
            fontWeight: 'bold',
            fontfamily: 'Arial',
        }
    })

    return(

        <View style = {styles.row}>
            {!!title && <Text style = {styles.title}>{title}</Text>}
            {!!text && <Text style = {styles.text}>{text}</Text>}
        </View>

    )

}

export default PdfTextContainer