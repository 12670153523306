import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Armazem } from "@interfaces/Armazem"

interface State {
    armazens: Armazem[] | null
    armazem: Armazem | null
    currentArmazem: Armazem | null
}

const initialState: State = {
    armazens: null,
    armazem: null,
    currentArmazem: null
}

const ArmazemSlice = createSlice({
    name: 'Armazem',
    initialState,
    reducers: {
        setArmazens: (state, action: PayloadAction<Armazem[]>) => {
            state.armazens = action.payload
        },
        setArmazem: (state, action: PayloadAction<Armazem>) => {
            state.armazem = action.payload
        },
        setCurrentArmazem: (state, action: PayloadAction<Armazem>) => {
            state.currentArmazem = action.payload
        },
    }
})

export const { setArmazens, setArmazem, setCurrentArmazem } = ArmazemSlice.actions
export default ArmazemSlice.reducer