import React from 'react'
import { Link } from 'react-router-dom'
import { ListRowProps } from '../types'
import createClassName from '../../../utils/createClassName'

const ListRow: React.FC <ListRowProps> = props => {

    return(

        <li className = {`listRow ${!!props.zebra ? 'zebra' : ''}`} onClick = {props.onClick}>
            {(props.link && (
                <Link to = {props.link}>
                    <Container {...props} />
                </Link>
            )) || <Container {...props} />}
        </li>

    )

}

const Container: React.FC <ListRowProps> = ({ children, padding = true, grid, height, status, omitColumns, onClick }) => {

    const newGrid = omitColumns && omitColumns.length > 0 ? grid.split(' ').filter((item, index) => !omitColumns.includes(index)).join(' ') : grid
    const newChildren = omitColumns && omitColumns.length > 0 ? (children as any[]).filter((item, index) => !omitColumns.includes(index)) : children

    const rowClasses = createClassName('listRowContainer', [padding ? 'padding' : 'noPadding', status && `status-${status}`, !!onClick && 'click'])

    return <ul className = {rowClasses} style = {{gridTemplateColumns: newGrid, height}}>{newChildren}</ul>

}

export default ListRow