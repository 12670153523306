import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { getAcessoGrupos } from '@modules/acesso/controllers/grupoController'
import { getAcessoSolicitacoes } from '@modules/acesso/controllers/solicitacaoController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ListSolicitacoes from './components/ListSolicitacoes'
import ModalSolicitacao from './components/ModalSolicitacao'
import ModalSaveSolicitacao from './components/ModalSaveSolicitacao'

const AcessoSolicitacaoLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    useEffect(() => {
        getAcessoSolicitacoes(dispatch)
    }, [dispatch])

    useEffect(() => {
        if(!!userData){
            getAcessoGrupos(dispatch, userData)
        }
    }, [dispatch, userData])

    return(

        <>
            <Section>
                <SectionBox
                    title = "Solicitações de acesso"
                    padding = {false}
                >
                    <ListSolicitacoes />
                </SectionBox>
            </Section>
            <ModalSolicitacao />
            <ModalSaveSolicitacao />
        </>

    )

}

export default AcessoSolicitacaoLista