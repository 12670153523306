import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserData } from "../interfaces/UserData"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { responseInitialValues } from "@utils/response"

interface State {
    requestSendAuthLogin: ResponseDefault<UserData>
    requestCreateAuthAccessRequest: ResponseDefault<any>
}

const initialState: State = {
    requestSendAuthLogin: responseInitialValues,
    requestCreateAuthAccessRequest: responseInitialValues,
}

const requestAuthSlice = createSlice({
    name: 'requestAuth',
    initialState,
    reducers: {
        setRequestSendAuthLoginData: (state, action: PayloadAction<ResponsePattern<UserData>>) => {
            state.requestSendAuthLogin.data = action.payload
            state.requestSendAuthLogin.loading = false
            state.requestSendAuthLogin.error = false
        },
        setRequestSendAuthLoginLoading: (state) => {
            state.requestSendAuthLogin.loading = true
            state.requestSendAuthLogin.error = false
        },
        setRequestSendAuthLoginError: (state) => {
            state.requestSendAuthLogin.loading = false
            state.requestSendAuthLogin.error = true
        },
        setRequestSendAuthLoginMessage: (state, action: PayloadAction<string>) => {
            state.requestSendAuthLogin.message = action.payload
        },
        resetRequestSendAuthLogin: (state) => {
            state.requestSendAuthLogin = {...responseInitialValues}
        },

        setRequestCreateAuthAccessRequestData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateAuthAccessRequest.data = action.payload
            state.requestCreateAuthAccessRequest.loading = false
            state.requestCreateAuthAccessRequest.error = false
        },
        setRequestCreateAuthAccessRequestLoading: (state: State) => {
            state.requestCreateAuthAccessRequest.loading = true
            state.requestCreateAuthAccessRequest.error = false
        },
        setRequestCreateAuthAccessRequestError: (state: State) => {
            state.requestCreateAuthAccessRequest.loading = false
            state.requestCreateAuthAccessRequest.error = true
        },
        setRequestCreateAuthAccessRequestMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateAuthAccessRequest.message = action.payload
        },
        resetRequestCreateAuthAccessRequest: (state: State) => {
            state.requestCreateAuthAccessRequest = {...responseInitialValues}
        },
    }
})

const actions = requestAuthSlice.actions

export const requestSendAuthLogin = {
    data: actions.setRequestSendAuthLoginData,
    loading: actions.setRequestSendAuthLoginLoading,
    error: actions.setRequestSendAuthLoginError,
    message: actions.setRequestSendAuthLoginMessage,
    reset: actions.resetRequestSendAuthLogin
}

export const requestCreateAuthAccessRequest = {
    data: actions.setRequestCreateAuthAccessRequestData,
    loading: actions.setRequestCreateAuthAccessRequestLoading,
    error: actions.setRequestCreateAuthAccessRequestError,
    message: actions.setRequestCreateAuthAccessRequestMessage,
    reset: actions.resetRequestCreateAuthAccessRequest,
}

export default requestAuthSlice.reducer