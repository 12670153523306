import ENV from "@env/index"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import storage from "@utils/storage"
import setAuthRoutes from "../scripts/setAuthRoutes"
import { Rota, UserData } from "../interfaces/UserData"

interface State {
    userData: UserData | null
    loading: boolean
    isLogged: boolean
    authRoutes: Rota[]
}

const initialState: State = {
    userData: null,
    loading: true,
    isLogged: false,
    authRoutes: [],
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthLogin: (state, action: PayloadAction<UserData>) => {
            state.userData = action.payload
            state.loading = false
            state.isLogged = true

            state.authRoutes = setAuthRoutes(action.payload)

            storage.setObject('user' ,action.payload)
            storage.setItem('flagSystem', ENV.FLAG_SYSTEM)
        },
        setAuthLogout: (state) => {
            state.userData = null
            state.isLogged = false
            
            storage.remove('user')
            window.location.reload()
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAuthIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLogged = action.payload
        },
    }
})

export const { setAuthLogin, setAuthLogout, setAuthLoading, setAuthIsLoggedIn } = authSlice.actions
export default authSlice.reducer