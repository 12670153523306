import { RouteType } from "@routes/interfaces"

const acessoRoutes: RouteType[] = [
    {
        key: 'acesso',
        path: 'acesso',
        title: 'Acesso',
        userVerification: true,
        subRoutes: [
            { key: 'acesso-grupos', path: 'grupos', title: 'Grupos de acesso', userVerification: true },
            { key: 'acesso-grupo', path: 'grupos/:idGrupo', title: 'Grupo de acesso', userVerification: true },
            { key: 'acesso-usuarios', path: 'usuarios', title: 'Usuários', userVerification: true },
            { key: 'acesso-usuarios', path: 'usuarios/:idUsuario', title: 'Usuário', userVerification: true },
            { key: 'acessoSolicitacaoLista', path: 'solicitacoes', title: 'Solicitações de acesso', userVerification: true },
        ]
    }
]

export default acessoRoutes