import React from 'react'
import Modal from '@components/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setOpenModalDesbloqueio } from '../../reducers/agendamentoConsultaBloqueadosReducer'
import ButtonGroup from '@components/Button/Group'
import { mdiCheck } from '@mdi/js'
import Button from '@components/Button'
import { setAgendamentoDesbloqueio } from '@modules/agendamento/controllers'

const ModalDesbloqueio: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentAgendamentoBloqueado } = useAppSelector(s => s.agendamento)
    const { openModalDesbloqueio, modalDesbloqueioValues } = useAppSelector(s => s.agendamentoConsultaBloqueados)
    const { requestSetAgendamentoDebloqueio } = useAppSelector(s => s.requestAgendamento)

    const TextGroup: React.FC <{title: string, text: string | number}> = ({ title, text }) => (
        <div className = "textGroup">
            <span>{title}</span>
            <span>{text}</span>
        </div>
    )

    return(

        <Modal title = "Confirmar desbloqueio" width = {400} open = {openModalDesbloqueio} setOpen = {setOpenModalDesbloqueio}>
            {!!currentAgendamentoBloqueado && (
                <>
                    <span className = "modalBoxMessage">Deseja desbloquear esse agendamento?</span>
                    <div className = "grid grid-cols-2 mt-4 gap-2">
                        <TextGroup
                            title = "Armazém"
                            text = {currentAgendamentoBloqueado.descricaoArmazem}
                        />
                        <TextGroup
                            title = "Tipo"
                            text = {currentAgendamentoBloqueado.tipo === 'C' ? 'Carga' : 'Descarga'}
                        />
                        <TextGroup
                            title = "Motorista"
                            text = {currentAgendamentoBloqueado.nomeMotorista}
                        />
                        <TextGroup
                            title = "Placa"
                            text = {currentAgendamentoBloqueado.placa}
                        />
                        <TextGroup
                            title = "Janela"
                            text = {currentAgendamentoBloqueado.nrDoca}
                        />
                        <TextGroup
                            title = "Data"
                            text = {`${currentAgendamentoBloqueado.data} ${currentAgendamentoBloqueado.hora}`}
                        />
                    </div>
                    <ButtonGroup>
                        <Button
                            label = "Cancelar"
                            onClick = {() => dispatch(setOpenModalDesbloqueio(false))}
                        />
                        <Button
                            icon = {mdiCheck}
                            loading = {requestSetAgendamentoDebloqueio.loading}
                            label = "Desbloquear"
                            status = "success"
                            onClick = {() => {
                                setAgendamentoDesbloqueio(dispatch, modalDesbloqueioValues!.guid!, {...modalDesbloqueioValues!}, userData!)
                            }}
                        />
                    </ButtonGroup>
                </>
            )}
        </Modal>

    )

}

export default ModalDesbloqueio