import React from 'react'
import Icon from '@mdi/react'
import { mdiClockOutline } from '@mdi/js'
import status from '@constants/status'
import { useAppSelector } from '@redux/hooks'

const LabelsAgendamento: React.FC = () => {

    const { agendamentos } = useAppSelector(s => s.agendamento)

    const labels = [
        { label: `Carga (${agendamentos?.filter(f => f.tipo === 'C').length ?? 0})`, id: 5, weight: (agendamentos?.filter(f => f.tipo === 'C').reduce((acc, curr) => acc + Number(curr.peso), 0) ?? 0).toFixed(2) },
        { label: `Descarga (${agendamentos?.filter(f => f.tipo === 'D').length ?? 0})`, id: 6, weight: (agendamentos?.filter(f => f.tipo === 'D').reduce((acc, curr) => acc + Number(curr.peso), 0) ?? 0).toFixed(2) }
    ]

    return(

        <div id = "labelsContainer">
            <div className = "itensGroup">
                <span className = "w-full mb-2">Legendas do Agendamento</span>
                {labels.map(({ id, label, weight }) => (
                    <div key = {id} className = {`boxLabel label-${id}`}>
                        {label}
                        <span className = "weight">{weight} ton</span>
                    </div>
                ))}
            </div>
            <div className = "itensGroup">
                <span className = "w-full mb-2">Legendas dos Status do Agendamento</span>
                {status.map(({ id, label }) => (
                    <div key = {id} className = {`boxStatus status-${id}`}>
                        <Icon path = {mdiClockOutline} size = "16" />
                        {label}
                    </div>
                ))}
            </div>
        </div>

    )

}

export default LabelsAgendamento