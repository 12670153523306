import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createClassName from '@utils/createClassName'
import { setSelectedArmazens } from '../../reducers/armazenEditarReducer'
import { PainelItemProps } from './types'

const PaintelItem: React.FC <PainelItemProps> = ({ hour, dock }) => {

    const dispatch = useAppDispatch()
    const { agendamentos } = useAppSelector(s => s.agendamento)
    const { armazemBloqueios, selectedArmazens } = useAppSelector(s => s.armazemEditar)

    const findInAgendamentos = agendamentos?.find(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`)
    const findInBloqueios = armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)
    const findInSelected = selectedArmazens.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)

    const painelItemClasses = createClassName("selectBox flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap", [
        (!!findInBloqueios && !findInAgendamentos) && "bg-red-500",
        (findInAgendamentos) && "bg-yellow-500 cursor-not-allowed",
        (!findInAgendamentos) && "cursor-pointer",
        // (!findInAgendamentos && !!findInBloqueios) && "bg-red-500 cursor-not-allowed",
        (!!findInSelected) && "selected"
    ])

    return(

        <>
            <td
                id = {`h${hour}d${dock}`}
                className = {painelItemClasses}
                data-hour = {hour}
                data-dock = {dock}
                onClick = {() => {
                    if(findInAgendamentos) return
                    
                    dispatch(setSelectedArmazens({ horario: hour, nroDoca: dock }))
                    /*
                    if(armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)){
                        dispatch(setOpenModalDesbloquear(true))
                    }else{
                        dispatch(setOpenModalBloquear(true))
                    }
                    */
                }}
            >
                {!!findInBloqueios?.nroDocumentoVinculo && (
                    <>{findInBloqueios.nroDocumentoVinculo}</>
                )}
            </td>
        </>

    )

}

export default PaintelItem