import React from 'react'
import createClassName from '@utils/createClassName'
import { ButtonGroupProps } from '../types'

const ButtonGroup: React.FC <ButtonGroupProps> = ({ children }) => {

    const btnGroupClassName = createClassName('btnGroup')

    return <div className = {btnGroupClassName}>{children}</div>

}

export default ButtonGroup