import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import 'overlayscrollbars/overlayscrollbars.css'
import '@styles/vars.scss'
import '@styles/app.scss'
import store from '@redux/store'
import AppRoutes from './routes'
import AppProvider from '@modules/app/components/AppProvider'

const App: React.FC = () => {
    
    return(

        <>
            <ReduxProvider store = {store}>
                <AppRoutes />
                <AppProvider />
            </ReduxProvider>
        </>

    )

}

export default App