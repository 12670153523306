import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import numberedArray from '@utils/numberedArray'
import minutesInterval from '@utils/minutesInterval'
import createClassName from '@utils/createClassName'
import { setModalDesbloqueioValues, setOpenModalDesbloqueio } from '../../reducers/agendamentoConsultaBloqueadosReducer'
import conditionalRender from '@utils/conditionalRender'
import { setCurrentAgendamentoBloqueado } from '@modules/agendamento/reducers/agendamentoReducer'

const Painel: React.FC = () => {

    const dispatch = useAppDispatch()
    const { armazens } = useAppSelector(s => s.armazem)
    const { agendamentosBloqueados, currentArmazem, currentData } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentosBloqueados } = useAppSelector(s => s.requestAgendamento)

    const CR = conditionalRender(requestGetAgendamentosBloqueados, agendamentosBloqueados)

    return(

        <>
            {(CR.DATA && !!agendamentosBloqueados && !!armazens && !!currentArmazem) && (
                <div className = "tableContainer custom scroll mt-6">
                    <table className = "w-full custom">
                        <thead className = "flex">
                            <tr className = "flex w-full">
                                <th className = "w-[80px]">Hora</th>
                                {numberedArray(armazens.find(f => f.id === currentArmazem!)!.qtdeDocas ?? 0, false).map((dock, index) => (
                                    <th key = {index} className = "flex-1 min-w-[246px]">{`Janela ${dock}`}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className = "flex flex-col items-center justify-between overflow-scroll w-full max-h-[50vh] overlayScroll">
                            {minutesInterval(armazens.find(f => f.id === currentArmazem!)!.intervalo === '01:00' ? 60 : 30).map((hour, index) => (
                                <tr key = {index} className = "flex w-full">
                                    <td className = "w-[80px] flex items-center justify-center text-center">{hour}</td>
                                    {numberedArray(armazens.find(f => f.id === currentArmazem!)!.qtdeDocas ?? 0, false).map((dock, dockIndex) => (
                                        <td
                                            key = {dockIndex} 
                                            id = {`h${hour}d${dock}`}
                                            className = {createClassName("flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap", [!!agendamentosBloqueados.find(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`) ? 'bg-yellow-500 cursor-pointer selectBox' : 'pointer-events-none'])}
                                            data-hour = {hour}
                                            data-dock = {dock}
                                            onClick = {() => {
                                                const findInBloqueados = agendamentosBloqueados.find(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`)

                                                if(!!findInBloqueados){
                                                    dispatch(setCurrentAgendamentoBloqueado(findInBloqueados))
                                                    dispatch(setModalDesbloqueioValues({ guid: findInBloqueados.guid, idArmazem: currentArmazem.toString(), data: currentData }))
                                                    dispatch(setOpenModalDesbloqueio(true))
                                                }
                                            }}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>

    )

}

export default Painel