import storage from "@utils/storage"
import { TableConfig } from "../interfaces/TableConfig"

function createTableConfig(name: string, columns: string[], options?: any): TableConfig {
    return {
        name,
        columns,
        omitColumns: getOmitColumns(name),
        options,
    }
}

function getOmitColumns(listName: string){
    const omitColumns = storage.getObject<number[]>(listName)

    if(omitColumns && omitColumns.length > 0) return omitColumns
    else return []
}

function setOmitColumns(listName: string, currentColumns: number[], omitColumn: number){
    let newColumns = [...currentColumns]

    if(newColumns.includes(omitColumn)) newColumns = newColumns.filter(column => column !== omitColumn)
    else newColumns.push(omitColumn)

    storage.setObject(listName, newColumns)
    return newColumns
}

function handleOmitColumns(listConfig: TableConfig, setTableConfig: (configs: TableConfig) => void, omitColumn: number){
    setTableConfig({
        ...listConfig, 
        omitColumns: setOmitColumns(listConfig.name, listConfig.omitColumns, omitColumn)
    })
}

export { createTableConfig, getOmitColumns, setOmitColumns, handleOmitColumns }