import ENV from '@env/index'
import { DispatchType } from '@redux/types'
import info from '@utils/info'
import storage from '@utils/storage'
import { UserData } from '../interfaces/UserData'
import { setAuthLoading, setAuthLogin } from '../reducers/authReducer'

export default function getUser(dispatch: DispatchType){
    try {
        const localUser = storage.getObject<UserData>('user')
        const localflagSystem = storage.getItem<string>('flagSystem')
        
        if(!!localUser && localUser.nome){
            if((!!localflagSystem && localflagSystem === ENV.FLAG_SYSTEM) || process.env.NODE_ENV === 'production'){
                dispatch(setAuthLogin(localUser))
            }else throw new Error('Flag System not found')
        }else throw new Error('User not found')
    } catch (error) {
        info.error('getUser',error)
    } finally {
        dispatch(setAuthLoading(false))
    }
}