import React, { useCallback } from 'react'
//import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { ModalProps } from './types'
import './styles.scss'
import ReactPortal from '../ReactPortal'
import { useAppDispatch } from '@redux/hooks'
import handleFunction from '@utils/handleFunction'
import createClassName from '@utils/createClassName'

const Modal: React.FC <ModalProps> = ({ children, width, height, title, status, open, setOpen, actions, onClose }) => {

    const dispatch = useAppDispatch()

    const headerClasses = createClassName('modalBoxHeader', [status && `status ${status}`])

    const handleClose = useCallback(() => {
        if(open){
            if(onClose) onClose()
            handleFunction(dispatch, () => setOpen(false))
        }
    }, [dispatch, onClose, setOpen, open])

    return(

        <>
            {open && (
                <ReactPortal>
                    <div className = "modalWrapper">
                        <>
                            <ul className = "modalBox" style = {{width, height}}>
                                <li className = {headerClasses}>
                                    <h1>{title}</h1>
                                    <span className = "iconContainer" onClick = {handleClose}>
                                        <Icon path = {mdiClose} size = "24px" />
                                    </span>
                                </li>
                                <li className = "modalBoxMain overlayScroll">
                                    <div className = "modalBoxMainContainer">
                                        {children}
                                        {!!actions && actions.length > 0 && (
                                            <div className = "modalBoxMainActionsContainer">
                                                {actions.map((action, index) => (
                                                    <button key = {index} className = {action.theme ?? 'default'} onClick = {() => action.onClick()}>{action.label}</button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </>
                    </div>
                </ReactPortal>
            )}
        </>

    )

}

export default Modal