import * as yup from 'yup'

export default function formValues(){
    return {
        login: '', 
        senha: '',
    }
}

export const formSchema = yup.object().shape({
    login: yup.string().required('Campo obrigatório'),
    senha: yup.string().required('Campo obrigatório'),
})