import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiArrowLeft } from '@mdi/js'
import Form from '@components/Form'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { maskCep, maskCnpj, maskCpf, maskTelefone } from '@constants/masks'
import { createSolicitacoAcesso } from '@modules/auth/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formValues } from './config'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { requestCreateAuthAccessRequest } = useAppSelector(s => s.requestAuth)

    return(

        <Form.Box>
            <Formik
                initialValues = {formValues}
                // validationSchema = {formSchema}
                onSubmit = {v => createSolicitacoAcesso(dispatch, v)}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Section title = "Dados da empresa">
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "CNPJ"
                                        error = {!!errors.nroDocumento}
                                        inputID = "nroDocumento"
                                        inputName = "nroDocumento"
                                        mask = {maskCnpj}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Razão social"
                                        error = {!!errors.razaoSocial}
                                        inputID = "razaoSocial"
                                        inputName = "razaoSocial"
                                    />
                                </Form.Row>
                            </Form.Section>
                            <Form.Section title = "Endereço da empresa">
                                <Form.Row columns = {3}>
                                    <Form.Group
                                        label = "Endereço"
                                        error = {!!errors.endereco}
                                        inputID = "endereco"
                                        inputName = "endereco"
                                    />
                                    <Form.Group
                                        label = "Número"
                                        error = {!!errors.numero}
                                        inputID = "numero"
                                        inputName = "numero"
                                    />
                                    <Form.Group
                                        label = "Complemento"
                                        error = {!!errors.complemento}
                                        inputID = "complemento"
                                        inputName = "complemento"
                                    />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "Bairro"
                                        error = {!!errors.bairro}
                                        inputID = "bairro"
                                        inputName = "bairro"
                                    />
                                    <Form.Group
                                        label = "CEP"
                                        error = {!!errors.cep}
                                        inputID = "cep"
                                        inputName = "cep"
                                        mask = {maskCep}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "Cidade"
                                        error = {!!errors.cidade}
                                        inputID = "cidade"
                                        inputName = "cidade"
                                    />
                                    <Form.Group
                                        label = "Estado"
                                        error = {!!errors.estado}
                                        inputID = "estado"
                                        inputName = "estado"
                                    />
                                </Form.Row>
                            </Form.Section>
                            <Form.Section title = "Dados do responsável">
                                <Form.Row columns = {1}>
                                    <Form.Group
                                        label = "Nome"
                                        error = {!!errors.nomeResponsavel}
                                        inputID = "nomeResponsavel"
                                        inputName = "nomeResponsavel"
                                    />
                                </Form.Row>
                                <Form.Row columns = {3}>
                                    <Form.Group
                                        label = "E-mail"
                                        error = {!!errors.email}
                                        inputID = "email"
                                        inputName = "email"
                                        inputType = "email"
                                    />
                                    <Form.Group
                                        label = "CPF"
                                        error = {!!errors.cpfResponsavel}
                                        inputID = "cpfResponsavel"
                                        inputName = "cpfResponsavel"
                                        mask = {maskCpf}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Telefone"
                                        error = {!!errors.telefone}
                                        inputID = "telefone"
                                        inputName = "telefone"
                                        mask = {maskTelefone}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                            </Form.Section>
                            <Form.Section title = "Horário de atendimento">
                                <Form.Row grid = "1fr auto 1fr 1fr auto 1fr" columns = {4}>
                                    <Form.Group
                                        label = "Manhã entrada"
                                        error = {!!errors.atendimentoManhaEntrada}
                                        inputID = "atendimentoManhaEntrada"
                                        inputName = "atendimentoManhaEntrada"
                                        inputType = "time"
                                    />
                                    <span className = "fieldHelper">às</span>
                                    <Form.Group
                                        label = ""
                                        error = {!!errors.atendimentoManhaSaida}
                                        inputID = "atendimentoManhaSaida"
                                        inputName = "atendimentoManhaSaida"
                                        inputType = "time"
                                    />
                                    <Form.Group
                                        label = "Tarde entrada"
                                        error = {!!errors.atendimentoTardeEntrada}
                                        inputID = "atendimentoTardeEntrada"
                                        inputName = "atendimentoTardeEntrada"
                                        inputType = "time"
                                    />
                                    <span className = "fieldHelper">às</span>
                                    <Form.Group
                                        label = ""
                                        error = {!!errors.atendimentoTardeSaida}
                                        inputID = "atendimentoTardeSaida"
                                        inputName = "atendimentoTardeSaida"
                                        inputType = "time"
                                    />
                                </Form.Row>
                            </Form.Section>
                            <ButtonGroup>
                                <Button
                                    label = "Voltar"
                                    icon = {mdiArrowLeft}
                                    link = "/login"
                                />
                                <Button
                                    label = "Criar solicitação"
                                    status = "success"
                                    loading = {requestCreateAuthAccessRequest.loading}
                                    onClick = {handleSubmit}
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </Form.Box>

    )

}

export default FormCreate