import { RouteType } from "@routes/interfaces"

const modulosRoutes: RouteType[] = [
    {
        key: 'modulos',
        path: 'modulos',
        title: 'Módulos',
        icon: 'mdiMonitorDashboard',
        showSubRoutes: false,
        subRoutes: [
            { key: 'modulo', path: '', title: 'Módulos', userVerification: true }
        ]
    }
]

export default modulosRoutes