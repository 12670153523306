import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AcessoSolicitacao } from "../../interfaces/AcessoSolicitacao"

interface State {
    acessoSolicitacoes: AcessoSolicitacao[] | null
    currentAcessoSolicitacao: AcessoSolicitacao | null

    openModalSolicitacao: boolean
}

const initialState: State = {
    acessoSolicitacoes: null,
    currentAcessoSolicitacao: null,
    openModalSolicitacao: false,
}

const acessoSolicitacaoSlice = createSlice({
    name: 'acessoSolicitacao',
    initialState,
    reducers: {
        setAcessoSolicitacoes: (state, action: PayloadAction<AcessoSolicitacao[]>) => {
            state.acessoSolicitacoes = action.payload
        },
        setCurrentAcessoSolicitacao: (state, action: PayloadAction<AcessoSolicitacao>) => {
            state.currentAcessoSolicitacao = action.payload
        },

        setOpenModalSolicitacao: (state, action: PayloadAction<boolean>) => {
            state.openModalSolicitacao = action.payload
        },
    }
})

export const { setAcessoSolicitacoes, setCurrentAcessoSolicitacao, setOpenModalSolicitacao } = acessoSolicitacaoSlice.actions
export default acessoSolicitacaoSlice.reducer