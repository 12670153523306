import * as yup from 'yup'

export const createAcessoUsuariosFormValues = {
    idGrupoAcesso: null,
    nome: '',
    documento: '',
    email: '',
    telefone: '',
    login: '',
    senha: '',
}

export const createAcessoUsuariosFormSchema = yup.object().shape({
    idGrupoAcesso: yup.number().required('Campo obrigatório'),
    nome: yup.string().required('Campo obrigatório'),
    documento: yup.string().required('Campo obrigatório'),
    email: yup.string().email('E-mail inválido'),
    telefone: yup.string().required('Campo obrigatório'),
    login: yup.string().required('Campo obrigatório'),
    senha: yup.string().required('Campo obrigatório'),
})