import React, { useEffect } from 'react'
import { getArmazens } from '@modules/armazem/controllers'
import { getAgendamentosLista } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const AppProvider: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    useEffect(() => {
        if(!!userData){
            getArmazens(dispatch, userData)
            getAgendamentosLista(dispatch, userData)
        }
    }, [dispatch, userData])

    return null

}

export default AppProvider