import React from 'react'
import { useParams } from 'react-router'
import { mdiCancel } from '@mdi/js'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ButtonGroup from '@components/Button/Group'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { setOpenModalBloquear } from '../../reducers/armazenEditarReducer'
import { setBloqueio, setMultipleBloqueio } from '@modules/armazem/controllers'

const ModalBloquear: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { openModalBloquear, bloqueioValues, filterData, selectedArmazens } = useAppSelector(s => s.armazemEditar)
    const { requestSetArmazemBloqueio } = useAppSelector(s => s.requestArmazemEditar)
    const { guidArmazem } = useParams()

    const isMultiples = selectedArmazens.length > 0

    return(

        <Modal title = "Bloquear agendamento" width = {400} open = {openModalBloquear} setOpen = {setOpenModalBloquear}>
            {isMultiples && <span className = "modalBoxMessage">{`Deseja bloquear (${selectedArmazens.length}) janelas?`}</span>}
            {(!!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja bloquear o agendamento na janela ${bloqueioValues?.nroDoca} às ${bloqueioValues?.horario}?`}</span>}
            {(!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja bloquear todo o agendamento do dia ${filterData.split('-').reverse().join('/')}?`}</span>}
            <ButtonGroup>
                <Button
                    label = "Cancelar"
                    onClick = {() => dispatch(setOpenModalBloquear(false))}
                />
                <Button
                    icon = {mdiCancel}
                    loading = {requestSetArmazemBloqueio.loading}
                    label = "Bloquear"
                    status = "error"
                    onClick = {() => {
                        if(isMultiples) setMultipleBloqueio(dispatch, guidArmazem!, filterData, selectedArmazens, userData!)
                        else setBloqueio(dispatch, guidArmazem!, filterData, bloqueioValues, userData!)
                    }}
                />
            </ButtonGroup>
        </Modal>

    )

}

export default ModalBloquear