import React from 'react'
import createClassName from '@utils/createClassName'
import { TableColumnProps } from '../types'

const TableColumn: React.FC <TableColumnProps> = ({ children, width, className, colSpan, status, wrap = false }) => {

    const columnClasses = createClassName('tableColumnContent', [status && `status ${status}`])
    
    return(

        <td colSpan = {colSpan} width = {width} className = {className}>
            <div className = {columnClasses} style = {{flexWrap: wrap || width ? 'wrap' : 'nowrap'}}>{children}</div>
        </td>

    )

}

export default TableColumn