import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '@redux/hooks'

const ChangeRoute: React.FC = () => {

    const { isLogged } = useAppSelector(s => s.auth)

    return(
        <>
            {isLogged && <Navigate to = "/" />}
        </>
    )

}

export default ChangeRoute