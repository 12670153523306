import React from 'react'
import { ListProps } from '../types'
import '../styles.scss'
import { firstLetterToUpperCase } from '../../../utils/format'

const List: React.FC <ListProps> = ({ children, name }) => {

    return <div id = {`list${firstLetterToUpperCase(name)}`} className = "listContainer overlayScroll">{children}</div>

}

export default List