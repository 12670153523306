import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createClassName from '@utils/createClassName'
import { setPainelEditValues } from '../../reducers/agendamentoEditarReducer'
import { PainelItemProps } from './types'

const PainelItem: React.FC <PainelItemProps> = ({ hour, dock }) => {

    const dispatch = useAppDispatch()
    const { armazemBloqueios } = useAppSelector(s => s.armazemEditar)
    const { agendamentos, agendamentoLista, currentData } = useAppSelector(s => s.agendamento)
    const { painelEditValues } = useAppSelector(s => s.agendamentoEditar)

    const findInAgendamentos = !!agendamentos?.find(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`)
    const findInBloqueios = armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)

    const currentDate = agendamentoLista!.dtAgendaFormatada.split(' ')[0].split('/').reverse().join('-') === currentData

    const classes = createClassName("selectBox flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap", [
        findInAgendamentos && "bg-yellow-500 cursor-not-allowed",
        (!findInAgendamentos && findInBloqueios) && "bg-red-500 cursor-not-allowed",
        (!findInAgendamentos && !findInBloqueios) && "cursor-pointer",
        (currentDate && agendamentoLista!.dtAgendaFormatada.split(' ')[1]! === hour && agendamentoLista!.nrDoca === dock) && "bg-blue-500",
        (!!painelEditValues && painelEditValues.hour === hour && painelEditValues.dock === dock) && "bg-green-500",
    ])

    return(

        <>
            <td
                id = {`h${hour}d${dock}`}
                className = {classes}
                data-hour = {hour}
                data-dock = {dock}
                onClick = {() => {
                    if(!findInAgendamentos && !findInBloqueios){
                        if(painelEditValues?.hour === hour && painelEditValues?.dock === dock){
                            dispatch(setPainelEditValues(null))
                        }else{
                            dispatch(setPainelEditValues({ hour, dock }))
                        }
                    }
                }}
            />
        </>

    )

}

export default PainelItem