import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ModalSaveValues } from "../interfaces/ModalSaveValues"

interface State {
    openModalSave: boolean

    modalSaveValues: ModalSaveValues | null
}

const initialState: State = {
    openModalSave: false,
    modalSaveValues: null
}

const agendamentoCadastroHorarioSlice = createSlice({
    name: 'agendamentoCadastroHorario',
    initialState,
    reducers: {
        setOpenModalSave: (state, action: PayloadAction<boolean>) => {
            state.openModalSave = action.payload
        },

        setModalSaveValues: (state, action: PayloadAction<ModalSaveValues | null>) => {
            state.modalSaveValues = action.payload
        },
    }
})

export const { setOpenModalSave, setModalSaveValues } = agendamentoCadastroHorarioSlice.actions
export default agendamentoCadastroHorarioSlice.reducer