import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "../../../../utils/response"
import { ResponseDefault, ResponsePattern } from "../../../../utils/response/types"

interface State {
    requestGetAcessoModulo: ResponseDefault<any>
    requestAddAcessoModulo: ResponseDefault<any>
    requestUpdateAcessoModulo: ResponseDefault<any>
    requestRemoveAcessoModulo: ResponseDefault<any>
}

const initialState: State = {
    requestGetAcessoModulo: responseInitialValues,
    requestAddAcessoModulo: responseInitialValues,
    requestUpdateAcessoModulo: responseInitialValues,
    requestRemoveAcessoModulo: responseInitialValues,   
}

const requestAcessoModuloSlice = createSlice({
    name: 'requestAcessoModulo',
    initialState,
    reducers: {
        setRequestGetAcessoModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetAcessoModulo.data = action.payload
            state.requestGetAcessoModulo.loading = false
            state.requestGetAcessoModulo.error = false
        },
        setRequestGetAcessoModuloLoading: (state) => {
            state.requestGetAcessoModulo.loading = true
            state.requestGetAcessoModulo.error = false
        },
        setRequestGetAcessoModuloError: (state) => {
            state.requestGetAcessoModulo.loading = false
            state.requestGetAcessoModulo.error = true
        },
        setRequestGetAcessoModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestGetAcessoModulo.message = action.payload
        },
        setRequestGetAcessoModuloReset: (state) => {
            state.requestGetAcessoModulo = {...responseInitialValues}
        },

        setRequestAddAcessoModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestAddAcessoModulo.data = action.payload
            state.requestAddAcessoModulo.loading = false
            state.requestAddAcessoModulo.error = false
        },
        setRequestAddAcessoModuloLoading: (state) => {
            state.requestAddAcessoModulo.loading = true
            state.requestAddAcessoModulo.error = false
        },
        setRequestAddAcessoModuloError: (state) => {
            state.requestAddAcessoModulo.loading = false
            state.requestAddAcessoModulo.error = true
        },
        setRequestAddAcessoModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestAddAcessoModulo.message = action.payload
        },
        resetRequestAddAcessoModulo: (state) => {
            state.requestAddAcessoModulo = {...responseInitialValues}
        },

        setRequestUpdateAcessoModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateAcessoModulo.data = action.payload
            state.requestUpdateAcessoModulo.loading = false
            state.requestUpdateAcessoModulo.error = false
        },
        setRequestUpdateAcessoModuloLoading: (state) => {
            state.requestUpdateAcessoModulo.loading = true
            state.requestUpdateAcessoModulo.error = false
        },
        setRequestUpdateAcessoModuloError: (state) => {
            state.requestUpdateAcessoModulo.loading = false
            state.requestUpdateAcessoModulo.error = true
        },
        setRequestUpdateAcessoModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateAcessoModulo.message = action.payload
        },
        resetRequestUpdateAcessoModulo: (state) => {
            state.requestUpdateAcessoModulo = {...responseInitialValues}
        },

        setRequestRemoveAcessoModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveAcessoModulo.data = action.payload
            state.requestRemoveAcessoModulo.loading = false
            state.requestRemoveAcessoModulo.error = false
        },
        setRequestRemoveAcessoModuloLoading: (state) => {
            state.requestRemoveAcessoModulo.loading = true
            state.requestRemoveAcessoModulo.error = false
        },
        setRequestRemoveAcessoModuloError: (state) => {
            state.requestRemoveAcessoModulo.loading = false
            state.requestRemoveAcessoModulo.error = true
        },
        setRequestRemoveAcessoModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestRemoveAcessoModulo.message = action.payload
        },
        resetRequestRemoveAcessoModulo: (state) => {
            state.requestRemoveAcessoModulo = {...responseInitialValues}
        },
    }
})

export const requestGetAcessoModulo = {
    data: requestAcessoModuloSlice.actions.setRequestGetAcessoModuloData,
    loading: requestAcessoModuloSlice.actions.setRequestGetAcessoModuloLoading,
    error: requestAcessoModuloSlice.actions.setRequestGetAcessoModuloError,
    message: requestAcessoModuloSlice.actions.setRequestGetAcessoModuloMessage,
    reset: requestAcessoModuloSlice.actions.setRequestGetAcessoModuloReset,
}

export const requestAddAcessoModulo = {
    data: requestAcessoModuloSlice.actions.setRequestAddAcessoModuloData,
    loading: requestAcessoModuloSlice.actions.setRequestAddAcessoModuloLoading,
    error: requestAcessoModuloSlice.actions.setRequestAddAcessoModuloError,
    message: requestAcessoModuloSlice.actions.setRequestAddAcessoModuloMessage,
    reset: requestAcessoModuloSlice.actions.resetRequestAddAcessoModulo,
}

export const requestUpdateAcessoModulo = {
    data: requestAcessoModuloSlice.actions.setRequestUpdateAcessoModuloData,
    loading: requestAcessoModuloSlice.actions.setRequestUpdateAcessoModuloLoading,
    error: requestAcessoModuloSlice.actions.setRequestUpdateAcessoModuloError,
    message: requestAcessoModuloSlice.actions.setRequestUpdateAcessoModuloMessage,
    reset: requestAcessoModuloSlice.actions.resetRequestUpdateAcessoModulo,
}

export const requestRemoveAcessoModulo = {
    data: requestAcessoModuloSlice.actions.setRequestRemoveAcessoModuloData,
    loading: requestAcessoModuloSlice.actions.setRequestRemoveAcessoModuloLoading,
    error: requestAcessoModuloSlice.actions.setRequestRemoveAcessoModuloError,
    message: requestAcessoModuloSlice.actions.setRequestRemoveAcessoModuloMessage,
    reset: requestAcessoModuloSlice.actions.resetRequestRemoveAcessoModulo,
}

export default requestAcessoModuloSlice.reducer