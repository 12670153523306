import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "../../../../utils/response"
import { ResponseDefault, ResponsePattern } from "../../../../utils/response/types"

interface State {
    requestGetModulo: ResponseDefault<any>
    requestAddModulo: ResponseDefault<any>
    requestUpdateModulo: ResponseDefault<any>
    requestRemoveModulo: ResponseDefault<any>
}

const initialState: State = {
    requestGetModulo: responseInitialValues,
    requestAddModulo: responseInitialValues,
    requestUpdateModulo: responseInitialValues,
    requestRemoveModulo: responseInitialValues,   
}

const requestModuloSlice = createSlice({
    name: 'requestModulo',
    initialState,
    reducers: {
        setRequestGetModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetModulo.data = action.payload
            state.requestGetModulo.loading = false
            state.requestGetModulo.error = false
        },
        setRequestGetModuloLoading: (state) => {
            state.requestGetModulo.loading = true
            state.requestGetModulo.error = false
        },
        setRequestGetModuloError: (state) => {
            state.requestGetModulo.loading = false
            state.requestGetModulo.error = true
        },
        setRequestGetModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestGetModulo.message = action.payload
        },
        setRequestGetModuloReset: (state) => {
            state.requestGetModulo = {...responseInitialValues}
        },

        setRequestAddModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestAddModulo.data = action.payload
            state.requestAddModulo.loading = false
            state.requestAddModulo.error = false
        },
        setRequestAddModuloLoading: (state) => {
            state.requestAddModulo.loading = true
            state.requestAddModulo.error = false
        },
        setRequestAddModuloError: (state) => {
            state.requestAddModulo.loading = false
            state.requestAddModulo.error = true
        },
        setRequestAddModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestAddModulo.message = action.payload
        },
        resetRequestAddModulo: (state) => {
            state.requestAddModulo = {...responseInitialValues}
        },

        setRequestUpdateModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateModulo.data = action.payload
            state.requestUpdateModulo.loading = false
            state.requestUpdateModulo.error = false
        },
        setRequestUpdateModuloLoading: (state) => {
            state.requestUpdateModulo.loading = true
            state.requestUpdateModulo.error = false
        },
        setRequestUpdateModuloError: (state) => {
            state.requestUpdateModulo.loading = false
            state.requestUpdateModulo.error = true
        },
        setRequestUpdateModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateModulo.message = action.payload
        },
        resetRequestUpdateModulo: (state) => {
            state.requestUpdateModulo = {...responseInitialValues}
        },

        setRequestRemoveModuloData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveModulo.data = action.payload
            state.requestRemoveModulo.loading = false
            state.requestRemoveModulo.error = false
        },
        setRequestRemoveModuloLoading: (state) => {
            state.requestRemoveModulo.loading = true
            state.requestRemoveModulo.error = false
        },
        setRequestRemoveModuloError: (state) => {
            state.requestRemoveModulo.loading = false
            state.requestRemoveModulo.error = true
        },
        setRequestRemoveModuloMessage: (state, action: PayloadAction<string>) => {
            state.requestRemoveModulo.message = action.payload
        },
        resetRequestRemoveModulo: (state) => {
            state.requestRemoveModulo = {...responseInitialValues}
        },
    }
})

export const requestGetModuloActions = {
    data: requestModuloSlice.actions.setRequestGetModuloData,
    loading: requestModuloSlice.actions.setRequestGetModuloLoading,
    error: requestModuloSlice.actions.setRequestGetModuloError,
    message: requestModuloSlice.actions.setRequestGetModuloMessage,
    reset: requestModuloSlice.actions.setRequestGetModuloReset,
}

export const requestAddModuloActions = {
    data: requestModuloSlice.actions.setRequestAddModuloData,
    loading: requestModuloSlice.actions.setRequestAddModuloLoading,
    error: requestModuloSlice.actions.setRequestAddModuloError,
    message: requestModuloSlice.actions.setRequestAddModuloMessage,
    reset: requestModuloSlice.actions.resetRequestAddModulo,
}

export const requestUpdateModuloActions = {
    data: requestModuloSlice.actions.setRequestUpdateModuloData,
    loading: requestModuloSlice.actions.setRequestUpdateModuloLoading,
    error: requestModuloSlice.actions.setRequestUpdateModuloError,
    message: requestModuloSlice.actions.setRequestUpdateModuloMessage,
    reset: requestModuloSlice.actions.resetRequestUpdateModulo,
}

export const requestRemoveModuloActions = {
    data: requestModuloSlice.actions.setRequestRemoveModuloData,
    loading: requestModuloSlice.actions.setRequestRemoveModuloLoading,
    error: requestModuloSlice.actions.setRequestRemoveModuloError,
    message: requestModuloSlice.actions.setRequestRemoveModuloMessage,
    reset: requestModuloSlice.actions.resetRequestRemoveModulo,
}

export default requestModuloSlice.reducer