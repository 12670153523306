import React from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ViewFaq from './views/Faq'
import './styles.scss'

const SuporteFaq: React.FC = () => {

    return(

        <>
            <Section>
                <SectionBox
                    title = "FAQ - Perguntas Frequentes"
                >
                    <ViewFaq />
                </SectionBox>
            </Section>
        </>

    )

}

export default SuporteFaq