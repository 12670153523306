import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createClassName from '@utils/createClassName'
import { setModalSaveValues, setOpenModalSave } from '../../reducers/agendamentoCadastroHorarioReducer'
import { PainelItemProps } from './types'

const PainelItem: React.FC <PainelItemProps> = ({ hour, dock }) => {

    const dispatch = useAppDispatch()
    const { armazemBloqueios } = useAppSelector(s => s.armazemEditar)
    const { agendamentos } = useAppSelector(s => s.agendamento)

    const findInAgendamentos = !!agendamentos!.find(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`)
    const findInBloqueios = !!armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)

    const classes = createClassName("selectBox flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap", [
        findInAgendamentos && "bg-yellow-500 cursor-not-allowed",
        (!findInAgendamentos && findInBloqueios) && "bg-red-500 cursor-not-allowed",
        (!findInAgendamentos && !findInBloqueios) && "cursor-pointer"
    ])

    return(

        <>
            <td
                id = {`h${hour}d${dock}`}
                className = {classes}
                data-hour = {hour}
                data-dock = {dock}
                onClick = {() => {
                    if(!findInAgendamentos && !findInBloqueios){
                        dispatch(setModalSaveValues({ hour, dock, findInBloqueios }))
                        dispatch(setOpenModalSave(true))
                    }
                }}
            />
        </>

    )

}

export default PainelItem