import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel } from '@mdi/js'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import { getRelatorioAgendamentos } from '@modules/relatorio/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import createSelectItems from '@utils/createSelectItems'
import createExcel from '../../scripts/createExcel'
import { formSchema, formValues } from './config'
import ButtonGroup from '@components/Button/Group'
import storage from '@utils/storage'

const FormFilter: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { relatorioAgendamentos } = useAppSelector(s => s.relatorio)
    const { requestGetRelatorioAgendamentos } = useAppSelector(s => s.requestRelatorio)

    const CR = conditionaRender(requestGetRelatorioAgendamentos, relatorioAgendamentos, true)

    return(

        <>
            <Formik
                initialValues = {formValues}
                validationSchema = {formSchema}
                onSubmit = {v => getRelatorioAgendamentos(dispatch, v, userData!)}
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <FormikForm>
                        <Form.Container>
                            <Form.Row columns = {CR.DATA ? 5 : 4} grid = {CR.DATA ? "1fr 1fr 1fr auto auto" : "1fr 1fr 1fr auto"}>
                                <Select
                                    type = "outline"
                                    label = "Armazém"
                                    value = {values.idArmazem}
                                    error = {!!errors.idArmazem}
                                    field = "idArmazem"
                                    setFieldValue = {setFieldValue}
                                    items = {createSelectItems(armazens, 'descricao', 'id')}
                                />
                                <Form.Group
                                    inputID = "dtInicio"
                                    inputName = "dtInicio"
                                    inputType = "date"
                                    label = "Início"
                                />
                                <Form.Group
                                    inputID = "dtFim"
                                    inputName = "dtFim"
                                    inputType = "date"
                                    label = "Fim"
                                />
                                <Button
                                    icon = {mdiMagnify}
                                    status = "info"
                                    label = "Buscar"
                                    onClick = {handleSubmit}
                                />
                                {CR.DATA && (
                                    <ButtonGroup>
                                        <Button
                                            icon = {mdiMicrosoftExcel}
                                            label = "Gerar Excel"
                                            status = "success"
                                            onClick = {() => {
                                                createExcel(relatorioAgendamentos!)
                                            }}
                                        />
                                        <Button
                                            icon = {mdiFilePdfBox}
                                            label = "Gerar PDF"
                                            status = "success"
                                            link = "pdf"
                                            target = "_blank"
                                            onClick = {() => {
                                                storage.setObject('pdfRelatorioAgendamentos', relatorioAgendamentos!)
                                                storage.setObject('pdfRelatorioAgendamentosFilter', values)
                                            }}
                                        />
                                    </ButtonGroup>
                                )}
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormFilter