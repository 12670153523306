import { ModuloMenu } from "@modules/modulos/interfaces/ModuloMenu"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    currentModuloMenu: ModuloMenu | null
}

const initialState: State = {
    currentModuloMenu: null
}

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setCurrentModuloMenu: (state, action: PayloadAction<ModuloMenu | null>) => {
            state.currentModuloMenu = action.payload
        }
    }
})

export const { setCurrentModuloMenu } = menuSlice.actions
export default menuSlice.reducer