import React from 'react'
import TableContext from '@components/Table/context'
import { TableWrapperProps } from '../types'
import '../../../styles.scss'

const TableWrapper: React.FC <TableWrapperProps> = ({ children, config }) => {

    return(

        <TableContext.Provider value = {config}>
            <div className = "tableWrapper">{children}</div>
        </TableContext.Provider>

    )

}

export default TableWrapper