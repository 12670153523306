import React, { useEffect } from 'react'
import './styles.scss'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import FormFilter from './components/FormFilter'
import Painel from './components/Painel'
import ModalAgendamento from './components/ModalAgendamento'
import LabelsAgendamento from './components/Labels'
import { useAppDispatch } from '@redux/hooks'
import { requestGetAgendamentosReducers } from '@modules/agendamento/reducers/requestAgendamentoReducer'

const AgendamentoConsulta: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(requestGetAgendamentosReducers.reset())
        }
    }, [dispatch])

    return(

        <>
            <Section>
                <SectionBox title = "Agendamentos">
                    <FormFilter />
                    <LabelsAgendamento />
                    <Painel />
                </SectionBox>
            </Section>
            <ModalAgendamento />
        </>

    )

}

export default AgendamentoConsulta