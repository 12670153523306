import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { SectionProps } from './types'

const PdfSection: React.FC <SectionProps> = ({ children, style }) => {

    const styles = StyleSheet.create({
        section: {
            width: '100%',
            ...style
        }
    })

    return(

        <View style = {styles.section}>
            {children}
        </View>

    )

}

export default PdfSection