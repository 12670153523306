import { AcessoUsuario } from "@modules/acesso/interfaces/AcessoUsuario"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    acessoUsuarios: AcessoUsuario[] | null

    currentAcessoUsuario: AcessoUsuario | null
}

const initialState: State = {
    acessoUsuarios: null,
    currentAcessoUsuario: null
}

const acessoUsuarioSlice = createSlice({
    name: 'acessoUsuario',
    initialState,
    reducers: {
        setAcessoUsuarios: (state, action: PayloadAction<AcessoUsuario[]>) => {
            state.acessoUsuarios = action.payload
        },
        setCurrentAcessoUsuario: (state, action: PayloadAction<AcessoUsuario>) => {
            state.currentAcessoUsuario = action.payload
        },
    }
})

export const { setAcessoUsuarios, setCurrentAcessoUsuario } = acessoUsuarioSlice.actions
export default acessoUsuarioSlice.reducer