import React from 'react'
import List, { ListColumn, ListLoader, ListMessage, ListRow, ListSection } from '@components/List'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const ListAgendamentos: React.FC = () => {

    const { relatorioAgendamentos } = useAppSelector(s => s.relatorio)
    const { requestGetRelatorioAgendamentos } = useAppSelector(s => s.requestRelatorio)

    const CR = conditionaRender(requestGetRelatorioAgendamentos, relatorioAgendamentos, true)
    const grid = '60px 150px 40px 60px 80px 70px 1fr 100px 1fr repeat(3, 120px) 65px 40px'

    return(

        <>
            <List name = "relatorioAgendamentos">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {['Código', 'Data', 'Janela', 'Peso', 'Tipo', 'Placa', 'Tipo de Veículo', 'Status', 'Cliente', 'Usuário', 'Transportadora', 'Motorista', 'Categoria', 'Ativo'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection>
                    <>
                        {CR.LOADING && <ListLoader />}
                        {CR.NODATA && <ListMessage text = "Não há relatórios!" />}
                        {CR.DATA && relatorioAgendamentos?.map((item, index) => (
                            <ListRow
                                key = {index}
                                grid = {grid}
                            >
                                <ListColumn>{item.id}</ListColumn>
                                <ListColumn>{new Date(item.data).toLocaleString()}</ListColumn>
                                <ListColumn>{item.nrDoca}</ListColumn>
                                <ListColumn>{item.peso}</ListColumn>
                                <ListColumn>{item.tipo === 'C' ? 'Carga' : 'Descarga'}</ListColumn>
                                <ListColumn>{item.placa}</ListColumn>
                                <ListColumn>{item.tipoVeiculo}</ListColumn>
                                <ListColumn>{item.descricaoStatus}</ListColumn>
                                <ListColumn>{item.nmCliente}</ListColumn>
                                <ListColumn>{item.nomeUsuarioCadastro}</ListColumn>
                                <ListColumn>{item.nomeTransportadoraCadastro}</ListColumn>
                                <ListColumn>{item.nomeMotorista}</ListColumn>
                                <ListColumn>{item.tipoMotorista}</ListColumn>
                                <ListColumn>{item.flagMotoristaAtivo}</ListColumn>
                            </ListRow>
                        ))}
                    </>
                </ListSection>
            </List>
        </>

    )

}

export default ListAgendamentos