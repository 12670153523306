import { AcessoModulo } from "@modules/acesso/interfaces/AcessoModulo"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    acessoModulos: AcessoModulo[] | null
    currentAcessoModulo: AcessoModulo | null
}

const initialState: State = {
    acessoModulos: null,
    currentAcessoModulo: null
}

const acessoModuloSlice = createSlice({
    name: 'acessoModulo',
    initialState,
    reducers: {
        setAcessoModulos: (state, action: PayloadAction<AcessoModulo[]>) => {
            state.acessoModulos = action.payload
        },
        setCurrentAcessoModulo: (state, action: PayloadAction<AcessoModulo | null>) => {
            state.currentAcessoModulo = action.payload
        },
    }
})

export const { setAcessoModulos, setCurrentAcessoModulo } = acessoModuloSlice.actions
export default acessoModuloSlice.reducer