import React from 'react'
import { useParams } from 'react-router'
import { mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import { getBloqueios } from '@modules/armazem/controllers'
import { getAgendamentos } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setFilterData } from '../../reducers/armazenEditarReducer'
import { formSchema, formValues } from './config'

const FormFilter: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazem } = useAppSelector(s => s.armazem)
    const { requestGetAgendamentos } = useAppSelector(s => s.requestAgendamento)
    const { requestGetArmazemBloqueios } = useAppSelector(s => s.requestArmazemEditar)

    const { guidArmazem } = useParams()

    return(

        <>
            <Formik
                initialValues = {formValues}
                validationSchema = {formSchema}
                onSubmit = {({ data }) => {
                    dispatch(setFilterData(data))

                    getBloqueios(dispatch, guidArmazem!, data, userData!)
                    getAgendamentos(dispatch, { data, idArmazem: armazem!.id.toString() }, userData!, guidArmazem)
                }}
            >
                {({ handleSubmit }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {2}>
                                <Form.Group
                                    inputID = "data"
                                    inputName = "data"
                                    inputType = "date"
                                    label = "Data"
                                />
                                <Button
                                    icon = {mdiMagnify}
                                    status = "info"
                                    label = "Buscar"
                                    loading = {requestGetAgendamentos.loading || requestGetArmazemBloqueios.loading}
                                    onClick = {handleSubmit}
                                />
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormFilter