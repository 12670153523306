import React from 'react'
import * as mdiIcon from "@mdi/js"
import Icon from '@mdi/react'
import { moduleIcons } from '@constants/moduleIcons'
import { ModuleIconSelectorProps } from './types'
import ModuleIconSelectorContainer from './container'
import ModuleIconSelectorBox from './box'
import './styles.scss'

const ModuleIconSelector: React.FC <ModuleIconSelectorProps> = props => {

    return(

        <>
            <ModuleIconSelectorContainer>
                <div className = "moduleIconSelectorBox typed">
                    <Icon path = {(mdiIcon as any)[props.currentIcon] ?? mdiIcon['mdiHelp']} size = "24px" />
                </div>
                {moduleIcons.map((item, index) => <ModuleIconSelectorBox {...props} {...item} key = {index} />)}
            </ModuleIconSelectorContainer>
        </>

    )

}

export default ModuleIconSelector