import PdfLine from "./Line"
import PdfPage from "./Page"
import PdfHeader from "./Header"
import PdfSection from "./Section"
import PdfContainer from "./Container"
import PdfSignature from "./Signature"
import PdfTextContainer from "./TextContainer"

const PDF = {
    Header: PdfHeader,
    Line: PdfLine,
    Section: PdfSection,
    Page: PdfPage,
    Container: PdfContainer,
    TextContainer: PdfTextContainer,
    Signature: PdfSignature,
}

export default PDF