import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PainelEditValues } from "../interfaces/PainelEditValues"

interface State {
    painelEditValues: PainelEditValues | null
}

const initialState: State = {
    painelEditValues: null
}

const agendamentoEditarSlice = createSlice({
    name: 'agendamentoEditar',
    initialState,
    reducers: {
        setPainelEditValues: (state, action: PayloadAction<PainelEditValues | null>) => {
            state.painelEditValues = action.payload
        },
    }
})

export const { setPainelEditValues } = agendamentoEditarSlice.actions
export default agendamentoEditarSlice.reducer