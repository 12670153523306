import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ModalDesbloqueioValues } from "../components/ModalDesbloqueio/types"

interface State {
    openModalDesbloqueio: boolean

    modalDesbloqueioValues: ModalDesbloqueioValues | null
}

const initialState: State = {
    openModalDesbloqueio: false,
    modalDesbloqueioValues: null
}

const agendamentoConsultaBloqueadosSlice = createSlice({
    name: 'agendamentoConsultaBloqueados',
    initialState,
    reducers: {
        setOpenModalDesbloqueio: (state, action: PayloadAction<boolean>) => {
            state.openModalDesbloqueio = action.payload
        },

        setModalDesbloqueioValues: (state, action: PayloadAction<ModalDesbloqueioValues>) => {
            state.modalDesbloqueioValues = action.payload
        },
    }
})

export const { setOpenModalDesbloqueio, setModalDesbloqueioValues } = agendamentoConsultaBloqueadosSlice.actions
export default agendamentoConsultaBloqueadosSlice.reducer