import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AcessoUsuario } from "@modules/acesso/interfaces/AcessoUsuario"
import { responseInitialValues } from "../../../../utils/response"
import { ResponseDefault, ResponsePattern } from "../../../../utils/response/types"

interface State {
    requestGetUsuarios: ResponseDefault<AcessoUsuario[]>
    requestUpdateUsuario: ResponseDefault<any>
    requestRemoveUsuario: ResponseDefault<any>
    requestCreateUsuario: ResponseDefault<any>
}

const initialState: State = {
    requestGetUsuarios: responseInitialValues,
    requestUpdateUsuario: responseInitialValues,
    requestRemoveUsuario: responseInitialValues,
    requestCreateUsuario: responseInitialValues,
}

const requestAcessoUsuarioSlice = createSlice({
    name: 'requestAcessoUsuario',
    initialState,
    reducers: {
        setRequestGetUsuariosData: (state, action: PayloadAction<ResponsePattern<AcessoUsuario[]>>) => {
            state.requestGetUsuarios.data = action.payload
            state.requestGetUsuarios.loading = false
            state.requestGetUsuarios.error = false
        },
        setRequestGetUsuariosLoading: (state) => {
            state.requestGetUsuarios.loading = true
            state.requestGetUsuarios.error = false
        },
        setRequestGetUsuariosError: (state) => {
            state.requestGetUsuarios.loading = false
            state.requestGetUsuarios.error = true
        },
        setRequestGetUsuariosMessage: (state, action: PayloadAction<string>) => {
            state.requestGetUsuarios.message = action.payload
        },
        resetRequestGetUsuarios: (state) => {
            state.requestGetUsuarios = {...responseInitialValues}
        },

        setRequestUpdateUsuarioData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateUsuario.data = action.payload
            state.requestUpdateUsuario.loading = false
            state.requestUpdateUsuario.error = false
        },
        setRequestUpdateUsuarioLoading: (state) => {
            state.requestUpdateUsuario.loading = true
            state.requestUpdateUsuario.error = false
        },
        setRequestUpdateUsuarioError: (state) => {
            state.requestUpdateUsuario.loading = false
            state.requestUpdateUsuario.error = true
        },
        setRequestUpdateUsuarioMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdateUsuario.message = action.payload
        },
        resetRequestUpdateUsuario: (state) => {
            state.requestUpdateUsuario = {...responseInitialValues}
        },

        setRequestRemoveUsuarioData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveUsuario.data = action.payload
            state.requestRemoveUsuario.loading = false
            state.requestRemoveUsuario.error = false
        },
        setRequestRemoveUsuarioLoading: (state) => {
            state.requestRemoveUsuario.loading = true
            state.requestRemoveUsuario.error = false
        },
        setRequestRemoveUsuarioError: (state) => {
            state.requestRemoveUsuario.loading = false
            state.requestRemoveUsuario.error = true
        },
        setRequestRemoveUsuarioMessage: (state, action: PayloadAction<string>) => {
            state.requestRemoveUsuario.message = action.payload
        },
        resetRequestRemoveUsuario: (state) => {
            state.requestRemoveUsuario = {...responseInitialValues}
        },

        setRequestCreateUsuarioData: (state, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateUsuario.data = action.payload
            state.requestCreateUsuario.loading = false
            state.requestCreateUsuario.error = false
        },
        setRequestCreateUsuarioLoading: (state) => {
            state.requestCreateUsuario.loading = true
            state.requestCreateUsuario.error = false
        },
        setRequestCreateUsuarioError: (state) => {
            state.requestCreateUsuario.loading = false
            state.requestCreateUsuario.error = true
        },
        setRequestCreateUsuarioMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateUsuario.message = action.payload
        },
        resetRequestCreateUsuario: (state) => {
            state.requestCreateUsuario = {...responseInitialValues}
        },
    }
})

export const requestGetAcessoUsuarios = {
    data: requestAcessoUsuarioSlice.actions.setRequestGetUsuariosData,
    loading: requestAcessoUsuarioSlice.actions.setRequestGetUsuariosLoading,
    error: requestAcessoUsuarioSlice.actions.setRequestGetUsuariosError,
    message: requestAcessoUsuarioSlice.actions.setRequestGetUsuariosMessage,
    reset: requestAcessoUsuarioSlice.actions.resetRequestGetUsuarios,
}

export const requestUpdateAcessoUsuario = {
    data: requestAcessoUsuarioSlice.actions.setRequestUpdateUsuarioData,
    loading: requestAcessoUsuarioSlice.actions.setRequestUpdateUsuarioLoading,
    error: requestAcessoUsuarioSlice.actions.setRequestUpdateUsuarioError,
    message: requestAcessoUsuarioSlice.actions.setRequestUpdateUsuarioMessage,
    reset: requestAcessoUsuarioSlice.actions.resetRequestUpdateUsuario,
}

export const requestRemoveAcessoUsuario = {
    data: requestAcessoUsuarioSlice.actions.setRequestRemoveUsuarioData,
    loading: requestAcessoUsuarioSlice.actions.setRequestRemoveUsuarioLoading,
    error: requestAcessoUsuarioSlice.actions.setRequestRemoveUsuarioError,
    message: requestAcessoUsuarioSlice.actions.setRequestRemoveUsuarioMessage,
    reset: requestAcessoUsuarioSlice.actions.resetRequestRemoveUsuario,
}

export const requestCreateAcessoUsuario = {
    data: requestAcessoUsuarioSlice.actions.setRequestCreateUsuarioData,
    loading: requestAcessoUsuarioSlice.actions.setRequestCreateUsuarioLoading,
    error: requestAcessoUsuarioSlice.actions.setRequestCreateUsuarioError,
    message: requestAcessoUsuarioSlice.actions.setRequestCreateUsuarioMessage,
    reset: requestAcessoUsuarioSlice.actions.resetRequestCreateUsuario,
}

export default requestAcessoUsuarioSlice.reducer