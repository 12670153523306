import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { reactLocalStorage } from "reactjs-localstorage"
import { SidebarSection } from "../interfaces/Sidebar"

interface State {
    sidebarOpen: boolean
    sidebarSections: SidebarSection
}

const initialState: State = {
    sidebarOpen: false,
    sidebarSections: {}
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.sidebarOpen = action.payload

            reactLocalStorage.set('sidebarOpen', action.payload)
        },
        toggleSidebarOpen: (state) => {
            const newValue = !state.sidebarOpen
            state.sidebarOpen = newValue

            reactLocalStorage.set('sidebarOpen', newValue)
        },

        setSidebarSections: (state, action: PayloadAction<SidebarSection>) => {
            state.sidebarSections = action.payload

            reactLocalStorage.setObject('sidebarSections', state.sidebarSections)
        },
        toggleSidebarSection: (state, action: PayloadAction<string>) => {
            state.sidebarSections[action.payload] = !state.sidebarSections[action.payload]

            reactLocalStorage.setObject('sidebarSections', state.sidebarSections)
        },
    }
})

export const { setSidebarOpen, toggleSidebarOpen, setSidebarSections, toggleSidebarSection } = appSlice.actions
export default appSlice.reducer