import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { handleResponse } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { formValues } from "../pages/AccessRequest/components/FormCreate/config"
import { requestCreateAuthAccessRequest } from "../reducers/requestAuthReducer"

export async function createSolicitacoAcesso(dispatch: DispatchType, values: typeof formValues){
    dispatch(requestCreateAuthAccessRequest.reset())
    dispatch(requestCreateAuthAccessRequest.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `http://3.228.242.23:9001/solicitacao-acesso`
    const body = {
        ...values,
        nroDocumento: values.nroDocumento,
        telefone: values.telefone,
        cep: values.cep.replace('-', ''),
        cpfResponsavel: values.cpfResponsavel,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createSolicitacoAcesso', dispatch, response, requestCreateAuthAccessRequest, options)
}