import React from 'react'
import { mdiMagnify, mdiPlus } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import { filterAgendamentosLista } from '@modules/agendamento/controllers'
import { useAppDispatch } from '@redux/hooks'

const FormSearch: React.FC = () => {

    const dispatch = useAppDispatch()

    return(

        <>
            <Formik
                initialValues = {{codigo: ''}}
                onSubmit = {({ codigo }) => filterAgendamentosLista(dispatch, codigo)}
            >
                {({ handleSubmit }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row grid = "auto auto auto" columns = {3}>
                                <Form.Group
                                    inputID = "codigo"
                                    inputName = "codigo"
                                    label = "Código"
                                />
                                <Button
                                    icon = {mdiMagnify}
                                    label = "Buscar"
                                    status = "info"
                                    onClick = {handleSubmit}
                                />
                                <Button
                                    icon = {mdiPlus}
                                    status = "success"
                                    label = "Novo agendamento"
                                    link = "/agendamento/cadastro"
                                />
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormSearch