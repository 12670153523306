import React, { useCallback, useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { mdiDelete, mdiPencil, mdiPlusCircle } from '@mdi/js'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ButtonGroup from '@components/Button/Group'
import SectionOption from '@components/Page/Section/option'
import List, { ListColumn, ListOption, ListRow, ListSection } from '@components/List'
import ModuleIconSelector from '@modules/modulos/components/ModuleIconSelector'
import { setCurrentModulo } from '@modules/modulos/reducers/modulo/moduloReducer'
import { setCurrentModuloMenu } from '@modules/modulos/reducers/menu/menuReducer'
import { addModuloMenu, removeModuloMenu, updateModuloMenu } from '@modules/modulos/controllers/menuController'
import { addModulo, getModulos, removeModulo, updateModulo } from '@modules/modulos/controllers/moduloController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { formModuloFields } from './constants'

const Modulo: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentModuloMenu } = useAppSelector(s => s.menu)
    const { modulos, currentModulo } = useAppSelector(s => s.modulo)
    const { requestAddMenu, requestUpdateMenu } = useAppSelector(s => s.requestMenu)
    const { requestGetModulo, requestAddModulo, requestUpdateModulo } = useAppSelector(s => s.requestModulo)
    const [openEditModule, setOpenEditModule] = useState(false)
    const [openAddModule, setOpenAddModule] = useState(false)
    const [openEditMenu, setOpenEditMenu] = useState(false)
    const [openAddMenu, setOpenAddMenu] = useState(false)

    const [openBaseModalModulo, setOpenBaseModalModulo] = useState(false)
    const [openBaseModalMenu, setOpenBaseModalMenu] = useState(false)

    const CR = conditionaRender(requestGetModulo, modulos)

    const handleCloseModal = useCallback(() => {
        setOpenEditModule(false)
        setOpenAddModule(false)
        setOpenEditMenu(false)
        setOpenAddMenu(false)
        setOpenBaseModalModulo(false)
        setOpenBaseModalMenu(false)

        dispatch(setCurrentModulo(null))
        dispatch(setCurrentModuloMenu(null))
    }, [dispatch])

    const getTitle = useCallback(() => {
        if(openEditModule) return 'Editar módulo'
        if(openAddModule) return 'Adicionar módulo'
        if(openEditMenu) return 'Editar menu'
        if(openAddMenu) return 'Adicionar menu'
        return ''
    }, [openEditModule, openAddModule, openEditMenu, openAddMenu])

    const getData = useCallback(() => {
        getModulos(dispatch)
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [dispatch, getData])

    return(

        <>
            <Section direction = "row" align = "end">
                <Button
                    label = "Criar módulo"
                    status = "success"
                    onClick = {() => {
                        setOpenAddModule(true)
                        setOpenBaseModalModulo(true)
                    }}
                />
            </Section>
            {CR.DATA && modulos!.map(modulo => (
                <Section key = {modulo.id}>
                    <SectionBox
                        title = {`${modulo.nome} - ${modulo.ordem}`}
                        padding = {false}
                        right = {
                            <>
                                <SectionOption
                                    icon = {mdiDelete}
                                    tooltip = "Remover"
                                    onClick = {() => {
                                        removeModulo(dispatch, { id: modulo.id, idUsuarioExclusao: userData!.id })
                                    }}
                                />
                                <SectionOption
                                    icon = {mdiPencil}
                                    tooltip = "Editar"
                                    onClick = {() => {
                                        dispatch(setCurrentModulo(modulo))
                                        setOpenEditModule(true)
                                        setOpenBaseModalModulo(true)
                                    }}
                                />
                                <SectionOption
                                    icon = {mdiPlusCircle}
                                    tooltip = "Criar"
                                    onClick = {() => {
                                        dispatch(setCurrentModulo(modulo))
                                        setOpenAddMenu(true)
                                        setOpenBaseModalMenu(true)
                                    }}
                                />
                            </>
                        }
                    >
                        <List name = "menu">
                            <ListSection>
                                {modulo.moduloMenus.map((menu, menuIndex) => (
                                    <ListRow key = {menuIndex} grid = "1fr 1fr auto">
                                        <ListColumn>{menu.nome}</ListColumn>
                                        <ListColumn>{menu.path}</ListColumn>
                                        <ListColumn>
                                            <ListOption
                                                icon = {mdiDelete}
                                                status = "error"
                                                onClick = {() => {
                                                    removeModuloMenu(dispatch, { id: menu.id, idUsuarioExclusao: userData!.id }, handleCloseModal)
                                                }}
                                            />
                                            <ListOption
                                                icon = {mdiPencil}
                                                onClick = {() => {
                                                    dispatch(setCurrentModulo(modulo))
                                                    dispatch(setCurrentModuloMenu(menu))
                                                    setOpenEditMenu(true)
                                                    setOpenBaseModalMenu(true)
                                                }}
                                            />
                                        </ListColumn>
                                    </ListRow>
                                ))}
                            </ListSection>
                        </List>
                    </SectionBox>
                </Section>
            ))}

            <Modal open = {openBaseModalModulo} setOpen = {setOpenBaseModalModulo} onClose = {handleCloseModal} width = {500} title = {getTitle()}>
                <Formik
                    initialValues = {{
                        nome: currentModulo?.nome ?? '',
                        path: currentModulo?.path ?? '',
                        icone: currentModulo?.icone ?? '',
                        ordem: Number(currentModulo?.ordem ?? 1),
                    }}
                    onSubmit = {v => {
                        if(openAddModule){
                            addModulo(dispatch, v, handleCloseModal)
                        }
                        if(openEditModule){
                            updateModulo(dispatch, { ...v, id: currentModulo!.id, idUsuarioAlteracao: userData!.id }, handleCloseModal)
                        }
                    }}
                >
                    {({ setFieldValue, values }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Render render = {formModuloFields} />
                                <ModuleIconSelector
                                    currentIcon = {values.icone}
                                    setCurrentIcon = {v => setFieldValue('icone', v)}
                                />
                                <Section direction = "row" align = "center" padding = {false}>
                                    <ButtonGroup>
                                        <Button
                                            type = "reset"
                                            label = "Cancelar"
                                            onClick = {handleCloseModal}
                                        />
                                        <Button
                                            type = "submit"
                                            label = "Salvar"
                                            loading = {requestAddModulo.loading || requestUpdateModulo.loading}
                                            status = "success"
                                        />
                                    </ButtonGroup>
                                </Section>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>

            <Modal open = {openBaseModalMenu} setOpen = {setOpenBaseModalMenu} onClose={handleCloseModal} title = {getTitle()}>
                <Formik
                    initialValues = {{
                        nome: currentModuloMenu?.nome ?? '',
                        path: currentModuloMenu?.path ??'',
                        ordem: Number(currentModuloMenu?.ordem ?? 1),
                        idModulo: currentModulo?.id,
                    }}
                    onSubmit = {v => {
                        if(openAddMenu){
                            addModuloMenu(dispatch, {...v, idUsuarioCadastro: userData!.id}, handleCloseModal)
                        }
                        if(openEditMenu){
                            updateModuloMenu(dispatch, {...v, id: currentModuloMenu!.id, idUsuarioAlteracao: userData!.id}, handleCloseModal)
                        }
                    }}
                >
                    {() => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group label = "Nome" inputID = "nome" inputName = "nome" />
                                    <Form.Group label = "Path" inputID = "path" inputName = "path" />
                                </Form.Row>
                                <Form.Row columns = {1}>
                                    <Form.Group label = "Ordem" inputID = "ordem" inputName = "ordem" inputType = "number" />
                                </Form.Row>
                                <Section direction = "row" align = "center" padding = {false}>
                                    <ButtonGroup>
                                        <Button
                                            type = "reset"
                                            label = "Cancelar"
                                            onClick = {handleCloseModal}
                                        />
                                        <Button
                                            type = "submit"
                                            label = "Salvar"
                                            loading = {requestAddMenu.loading || requestUpdateMenu.loading}
                                            status = "success"
                                        />
                                    </ButtonGroup>
                                </Section>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </>

    )

}

export default Modulo