import ENV from '@env/index'
import { DispatchType } from '@redux/types'
import request from '@utils/request'
import { ResponsePattern } from '@utils/response/types'
import { handleResponse, initRequest } from '@utils/response'
import { AcessoModulo } from '../interfaces/AcessoModulo'
import * as R from '../reducers/modulo/requestAcessoModuloReducer'
import { setAcessoModulos } from '../reducers/modulo/acessoModuloReducer'

export async function getAcessoModulos(dispatch: DispatchType){
    initRequest(dispatch, R.requestGetAcessoModulo)

    const endpoint = `${ENV.APP_ENDPOINT}/modulos`
    const response = await request.get<ResponsePattern<AcessoModulo[]>>({ endpoint })

    handleResponse('getAcessoModulo', dispatch, response, R.requestGetAcessoModulo)
    .then(data => {
        dispatch(setAcessoModulos(data))
    })
}