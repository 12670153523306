import React, { useEffect } from 'react'
import './styles.scss'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { getAgendamentosLista } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormSearch from './components/FormSearch'
import ModalAgendamento from './components/ModalAgendamento'
import TableAgendamentos from './components/TableAgendamentos'

const AgendamentoLista: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    useEffect(() => {
        if(!!userData){
            getAgendamentosLista(dispatch, userData)
        }
    }, [dispatch, userData])

    return(

        <>
            <Section>
                <SectionBox
                    title = "Agendamentos"
                    padding = {false}
                    right = {<FormSearch />}
                >
                    <TableAgendamentos />
                </SectionBox>
            </Section>
            <ModalAgendamento />
        </>

    )

}

export default AgendamentoLista