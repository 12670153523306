import React from 'react'
import Icon from '@mdi/react'
import { mdiClock } from '@mdi/js'
import './styles.scss'
import { Agendamento } from '@interfaces/Agendamento'
import createClassName from '@utils/createClassName'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getAgendamento } from '@modules/agendamento/controllers'
import { setCurrentAgendamento } from '@modules/agendamento/reducers/agendamentoReducer'
import { setOpenModalAgendamento } from '../../reducers/agendamentoConsultaReducer'

const PainelBox: React.FC <Agendamento> = agendamento => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const boxClasses = createClassName('painelBox', [`label-${agendamento.idLegenda}`, `status-${agendamento.idStatus}`])

    const handleClick = () => {
        dispatch(setCurrentAgendamento(agendamento))
        getAgendamento(dispatch, agendamento.id, userData!)
        dispatch(setOpenModalAgendamento(true))
    }

    return(

        <div className = {boxClasses}>
            <div className = "painelBoxTouchable" onClick = {handleClick}>
                <span>{`${agendamento.tipo} | ${agendamento.docaCarregada ?? ''}`}</span>
                <Icon path = {mdiClock} size = "20px" />
                <span className = "text">{agendamento.placa}</span>
            </div>
        </div>

    )

}

export default PainelBox