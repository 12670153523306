import FormContainer from "./components/container"
import FormRow from "./components/row"
import FormGroup from "./components/group"
import FormRender from "./components/render"
import FormBox from "./components/box"
import FormSection from "./components/section"

const Form = {
    Box: FormBox,
    Container: FormContainer,
    Section: FormSection,
    Row: FormRow,
    Group: FormGroup,
    Render: FormRender,
}

export default Form
export {
    FormContainer, FormSection, FormRow, FormGroup, FormRender, FormBox,
}