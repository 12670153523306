import React from 'react'
import { mdiDelete, mdiEye, mdiPencil } from '@mdi/js'
import List, { ListColumn, ListLoader, ListMessage, ListOption, ListRow, ListSection } from '@components/List'
import { deleteAcessoSolicitacao } from '@modules/acesso/controllers/solicitacaoController'
import { setCurrentAcessoSolicitacao } from '@modules/acesso/reducers/acessoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { setOpenModalSaveSolicitacao, setOpenModalSolicitacao } from '../../reducers/acessoSolicitacoesListaReducer'

const ListSolicitacoes: React.FC = () => {

    const dispatch = useAppDispatch()
    const { acessoSolicitacoes } = useAppSelector(s => s.acessoSolicitacao)
    const { requestGetAcessoSolicitacoes } = useAppSelector(s => s.requestAcessoSolicitacao)

    const CR = conditionaRender(requestGetAcessoSolicitacoes, acessoSolicitacoes, true)
    const grid = "repeat(2, 20%) 10% 1fr repeat(2, 110px) 76px"

    return(

        <>
            <List name = "solicitacoes">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {['Razão', 'Endereço', 'Responsável', 'E-mail', 'CPF', 'Telefone', 'Opções'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection>
                    {CR.LOADING && <ListLoader />}
                    {CR.NODATA && <ListMessage text = "Não há solicitações!" />}
                    {CR.DATA && acessoSolicitacoes!.map((item, index) => (
                        <ListRow
                            key = {index}
                            grid = {grid}
                        >
                            <ListColumn>{item.razaoSocial}</ListColumn>
                            <ListColumn>{`${item.endereco}, ${item.cep}, ${item.bairro} - ${item.cidade}`}</ListColumn>
                            <ListColumn>{item.nomeResponsavel}</ListColumn>
                            <ListColumn>{item.email}</ListColumn>
                            <ListColumn>{item.cpfResponsavel}</ListColumn>
                            <ListColumn>{item.telefone}</ListColumn>
                            <ListColumn>
                                <ListOption
                                    icon = {mdiDelete}
                                    status = "error"
                                    onClick = {() => deleteAcessoSolicitacao(dispatch, item.id)}
                                />
                                <ListOption
                                    icon = {mdiPencil}
                                    onClick = {() => {
                                        dispatch(setCurrentAcessoSolicitacao(item))

                                        dispatch(setOpenModalSaveSolicitacao(true))
                                    }}
                                />
                                <ListOption
                                    icon = {mdiEye}
                                    onClick = {() => {
                                        dispatch(setCurrentAcessoSolicitacao(item))

                                        dispatch(setOpenModalSolicitacao(true))
                                    }}
                                />
                            </ListColumn>
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default ListSolicitacoes