import React, { useEffect, useState } from 'react'
import { mdiCheck, mdiDelete } from '@mdi/js'
import { Navigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import ButtonGroup from '@components/Button/Group'
import SectionBoxData from '@components/Page/Section/data'
import { enabledItems } from '@constants/selects'
import selectIntervalos from '@constants/select/intervalo'
import { editArmazem } from '@modules/armazem/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import sleep from '@utils/sleep'
import { setOpenModalRemove } from '../../reducers/armazenEditarReducer'
import ModalRemove from '../ModalRemove'
import formEditar from './config'

const FormEdit: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazem } = useAppSelector(s => s.armazem)
    const { requestEditArmazem, requestRemoveArmazem } = useAppSelector(s => s.requestArmazem)
    const [redirect, setRedirect] = useState(false)
    const [renderValues, setRenderValues] = useState(false)
    const { guidArmazem } = useParams()

    useEffect(() => {
        (async() => {
            if(!!armazem){
                setRenderValues(false)
                await sleep(100)
                setRenderValues(true)
            }
        })()
    }, [armazem])

    return(

        <>
            {redirect && <Navigate to = "/armazem" />}
            {renderValues && (
                <SectionBoxData>
                    <Formik
                        initialValues = {formEditar.setValues(armazem!)}
                        onSubmit = {v => editArmazem(dispatch, v, guidArmazem!, setRedirect, userData!)}
                    >
                        {({ handleSubmit, setFieldValue, values }) => (
                            <FormikForm>
                                <Form.Container padding = {false}>
                                    <Form.Section>
                                        <Form.Row columns = {4}>
                                            <Form.Group
                                                inputID = "descricao"
                                                inputName = "descricao"
                                                label = "Descrição"
                                            />
                                            <Select
                                                type = "outline"
                                                label = "Intervalo"
                                                value = {values.intervalo}
                                                field = "intervalo"
                                                setFieldValue = {setFieldValue}
                                                items = {selectIntervalos}
                                            />
                                            <Form.Group
                                                inputID = "qtdeDocas"
                                                inputName = "qtdeDocas"
                                                inputType = "number"
                                                label = "Quantidade de janelas"
                                            />
                                            <Form.Group
                                                inputID = "qtdeLimiteAgendamentosDia"
                                                inputName = "qtdeLimiteAgendamentosDia"
                                                inputType = "number"
                                                label = "Limite diário de agendamentos por placa"
                                            />
                                        </Form.Row>
                                        <Form.Row columns = {3}>
                                            <Select
                                                type = "outline"
                                                label = "Regra desbloqueio"
                                                field = "flagRegraAgendamento"
                                                value = {values.flagRegraAgendamento}
                                                setFieldValue = {setFieldValue}
                                                items = {enabledItems}
                                            />
                                        </Form.Row>
                                        <Form.Row columns = {2} align = "top">
                                            <Form.Group
                                                inputID = "endereco"
                                                inputName = "endereco"
                                                label = "Endereço"
                                            />
                                            <Form.Group
                                                inputID = "listaEmail"
                                                inputName = "listaEmail"
                                                label = "Emails"
                                                inputType = "textarea"
                                                inputRows = {10}
                                            />
                                        </Form.Row>
                                    </Form.Section>
                                    <Form.Section title = "Horário de atendimento">
                                        <Form.Row grid = "1fr auto 1fr 1fr auto 1fr" columns = {4}>
                                            <Form.Group
                                                label = "Manhã entrada"
                                                inputID = "atendimentoManhaEntrada"
                                                inputName = "atendimentoManhaEntrada"
                                                inputType = "time"
                                            />
                                            <span className = "fieldHelper">às</span>
                                            <Form.Group
                                                label = ""
                                                inputID = "atendimentoManhaSaida"
                                                inputName = "atendimentoManhaSaida"
                                                inputType = "time"
                                            />
                                            <Form.Group
                                                label = "Tarde entrada"
                                                inputID = "atendimentoTardeEntrada"
                                                inputName = "atendimentoTardeEntrada"
                                                inputType = "time"
                                            />
                                            <span className = "fieldHelper">às</span>
                                            <Form.Group
                                                label = ""
                                                inputID = "atendimentoTardeSaida"
                                                inputName = "atendimentoTardeSaida"
                                                inputType = "time"
                                            />
                                        </Form.Row>
                                    </Form.Section>
                                    <ButtonGroup>
                                        <Button
                                            icon = {mdiDelete}
                                            loading = {requestRemoveArmazem.loading}
                                            label = "Remover"
                                            status = "error"
                                            onClick = {() => dispatch(setOpenModalRemove(true))}
                                        />
                                        <Button
                                            icon = {mdiCheck}
                                            loading = {requestEditArmazem.loading}
                                            label = "Salvar"
                                            status = "success"
                                            onClick = {handleSubmit}
                                        />
                                    </ButtonGroup>
                                </Form.Container>
                            </FormikForm>
                        )}
                    </Formik>
                </SectionBoxData>
            )}
            <ModalRemove redirect = {setRedirect} />
        </>

    )

}

export default FormEdit