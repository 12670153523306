import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { RelatorioAgendamento } from '@modules/relatorio/interfaces/RelatorioAgendamento'

const Column: React.FC <{width: string, text: string | number}> = ({ width, text }) => {

    const styles = StyleSheet.create({
        column: {
            width,
            paddingHorizontal: 4
        },
        text: {
            fontSize: 6,
        }
    })

    return(

        <View style = {styles.column}>
            <Text style = {styles.text}>{text}</Text>
        </View>

    )

}

const Row: React.FC <RelatorioAgendamento> = data => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 4,
            borderBottom: "1px solid black",
        },
    })

    return(

        <View style = {styles.row}>
            <Column width = "30%" text = {data.nomeTransportadoraCadastro} />
            <Column width = "10%" text = {data.descricaoTipo} />
            <Column width = "20%" text = {data.nomeMotorista} />
            <Column width = "10%" text = {data.placa} />
            <Column width = "10%" text = {data.dtAgendaFormatada.split(' ')[1]} />
            <Column width = "20%" text = {data.listaNfs?.map(i => `${i.nroNota}-${i.nroSerie}`).join(', ')} />
        </View>

    )

}

export default Row