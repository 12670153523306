import React from 'react'
import { mdiDelete, mdiPrinter } from '@mdi/js'
import Modal from '@components/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setOpenModalAgendamento } from '../../reducers/agendamentoListaReducer'
import ButtonGroup from '@components/Button/Group'
import Button from '@components/Button'
import { removeAgendamento } from '@modules/agendamento/controllers'

const ModalAgendamento: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentAgendamentoLista } = useAppSelector(s => s.agendamento)
    const { openModalAgendamento } = useAppSelector(s => s.agendamentoLista)
    const { requestRemoveAgendamento } = useAppSelector(s => s.requestAgendamento)

    const TextGroup: React.FC <{title: string, text: string | number}> = ({ title, text }) => (
        <div className = "textGroup agendamentoLista">
            <span>{title}</span>
            <span>{text}</span>
        </div>
    )

    return(

        <Modal
            title = {`Agendamento - ${currentAgendamentoLista?.id}`}
            width = "800px"
            open = {openModalAgendamento}
            setOpen = {setOpenModalAgendamento}
        >
            {!!currentAgendamentoLista && (
                <>
                    <div className = "grid grid-cols-2 gap-2">
                        <TextGroup
                            title = "Armazém"
                            text = {currentAgendamentoLista.descricaoArmazem}
                        />
                        <TextGroup
                            title = "Cliente"
                            text = {currentAgendamentoLista.nmCliente}
                        />
                        <TextGroup
                            title = "Tipo"
                            text = {currentAgendamentoLista.tipo === 'C' ? 'Carga' : 'Descarga'}
                        />
                        <div />

                        <TextGroup
                            title = "Motorista"
                            text = {currentAgendamentoLista.nomeMotorista}
                        />
                        <TextGroup
                            title = "Documento"
                            text = {currentAgendamentoLista.docMotorista ?? '-'}
                        />
                    </div>

                    <div className = "grid grid-cols-4 gap-2 mt-2 mb-2">
                        <TextGroup
                            title = "Veículo"
                            text = {currentAgendamentoLista.tipoVeiculo}
                        />
                        <TextGroup
                            title = "Placa"
                            text = {currentAgendamentoLista.placa}
                        />
                        <TextGroup
                            title = "Placa 2"
                            text = {currentAgendamentoLista.placa2}
                        />
                        <TextGroup
                            title = "Placa 3"
                            text = {currentAgendamentoLista.placa3}
                        />
                    </div>

                    <div className = "grid grid-cols-2 gap-2">
                        <TextGroup
                            title = "Janela"
                            text = {currentAgendamentoLista.nrDoca}
                        />
                        <TextGroup
                            title = "Data"
                            text = {currentAgendamentoLista.dtAgendaFormatada}
                        />

                        <TextGroup
                            title = "Usuário"
                            text = {currentAgendamentoLista.nomeUsuarioCadastro}
                        />
                        <TextGroup
                            title = "Transportadora"
                            text = {currentAgendamentoLista.nomeTransportadoraCadastro}
                        />
                    </div>

                    <ButtonGroup>
                        <Button
                            label = "Imprimir"
                            icon = {mdiPrinter}
                            onClick = {() => window.open(currentAgendamentoLista.linkImpressao)}
                        />
                        <Button
                            label = "Remover"
                            icon = {mdiDelete}
                            loading = {requestRemoveAgendamento.loading}
                            status = "error"
                            onClick = {() => removeAgendamento(dispatch, currentAgendamentoLista.id, userData!)}
                        />
                    </ButtonGroup>
                </>
            )}
        </Modal>

    )

}

export default ModalAgendamento