import React, { useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import Table from '../Container/Table'
import TableHeader from '../Container/Header'
import TableLoader from '../Loader'
import TableOptions from '../Container/Options'
import TableWrapper from '../Container/Wrapper'
import TableMessage from '../Message'
import { TablePaginatorProps } from './types'

const TablePaginator = <P extends unknown>({
    data,
    config,
    conditionalRender,
    loading = false,
    options,
    renderItem
}: TablePaginatorProps<P>) => {

    const [currentPage, setCurrentPage] = React.useState(1)
    const [itemsPerPage] = React.useState(10)
    const [pages, setPages] = React.useState(0)

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = (data ?? []).slice(indexOfFirstItem, indexOfLastItem)

    const SHOW_LOADING = !!loading ? loading : !!conditionalRender ? conditionalRender.LOADING : false
    const SHOW_DATA = !SHOW_LOADING && (!!conditionalRender ? conditionalRender.DATA : true)

    const nextPage = () => {
        if(currentPage < pages) setCurrentPage(currentPage + 1)
    }

    const prevPage = () => {
        if(currentPage > 1) setCurrentPage(currentPage - 1)
    }

    const firstPage = () => {
        if(currentPage > 1) setCurrentPage(1)
    }

    const lastPage = () => {
        if(currentPage < pages) setCurrentPage(pages)
    }

    useEffect(() => {
        setPages(Math.ceil((data ?? []).length / itemsPerPage))
    }, [data, itemsPerPage])

    return(

        <TableWrapper config = {config}>
            {!!options && <TableOptions>{options}</TableOptions>}
            <Table>
                <TableHeader />
                <tbody>
                    {SHOW_LOADING && <TableLoader />}
                    {(((!!conditionalRender && conditionalRender.NODATA) || currentItems.length === 0) && (!!conditionalRender && !conditionalRender.LOADING)) && <TableMessage text = "Nenhum item encontrado!" />}
                    {SHOW_DATA && currentItems.map((item, index) => renderItem(item, index))}
                </tbody>
            </Table>
            <div className = "tablePaginatorFooter">
                <div className = "tablePaginatorFooterLeft tablePaginatorSection">
                    <span className = "tablePaginatorIndicator">Página {currentPage} de {pages}</span>
                </div>
                <div className = "tablePaginatorFooterRight tablePaginatorSection">
                    <div className = "tablePaginatorControls">
                        <button className = "tablePaginatorButton" onClick = {firstPage}>
                            <Icon path = {mdiChevronDoubleLeft} size = "24px" />
                        </button>
                        <button className = "tablePaginatorButton" onClick = {prevPage}>
                            <Icon path = {mdiChevronLeft} size = "24px" />
                        </button>
                        <button className = "tablePaginatorButton" onClick = {nextPage}>
                            <Icon path = {mdiChevronRight} size = "24px" />
                        </button>
                        <button className = "tablePaginatorButton" onClick = {lastPage}>
                            <Icon path = {mdiChevronDoubleRight} size = "24px" />
                        </button>
                    </div>
                </div>
            </div>
        </TableWrapper>

    )

}

export default TablePaginator