import React from 'react'
import FormCreate from './components/FormCreate'

const AccessRequest:React.FC = () => {

    return(

        <>
            <FormCreate />
        </>

    )

}

export default AccessRequest