import React, { PropsWithChildren } from 'react'
import { StyleSheet, Page, View } from '@react-pdf/renderer'

const PdfPage: React.FC <PropsWithChildren> = ({ children }) => {

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            flexGrow: 1,
            padding: 20,
            backgroundColor: '#FFF'
        },
        content: {
            flexGrow: 1
        },
    })

    return(

        <Page size = "A4" style = {styles.page} break>
            <View style = {styles.content}>
                {children}
            </View>
        </Page>

    )

}

export default PdfPage