import React from 'react'
import Modal from '@components/Modal'
import { useAppSelector } from '@redux/hooks'
import { setOpenModalSolicitacao } from '../../reducers/acessoSolicitacoesListaReducer'

const ModalSolicitacao: React.FC = () => {

    const { currentAcessoSolicitacao } = useAppSelector(s => s.acesso)
    const { openModalSolicitacao } = useAppSelector(s => s.acessoSolicitacoesLista)

    const TextGroup: React.FC <{title: string, text: string | number}> = ({ title, text }) => (
        <div className = "textGroup">
            <span>{title}</span>
            <span>{text}</span>
        </div>
    )

    return(

        <>
            <Modal
                title = {`Solicitação - ${currentAcessoSolicitacao?.id}`}
                width = "60%"
                open = {openModalSolicitacao}
                setOpen = {setOpenModalSolicitacao}
            >
                {!!currentAcessoSolicitacao && (
                    <>
                        <div className = "grid grid-cols-2 gap-2">
                            <TextGroup
                                title = "Razão Social"
                                text = {currentAcessoSolicitacao.razaoSocial}
                            />
                            <TextGroup
                                title = "CNPJ"
                                text = {currentAcessoSolicitacao.nroDocumento}
                            />
                        </div>
                        <div className = "grid grid-cols-1 gap-2 mt-4">
                            <TextGroup
                                title = "Endereço"
                                text = {`${currentAcessoSolicitacao.endereco}, ${currentAcessoSolicitacao.cep}, ${currentAcessoSolicitacao.bairro} - ${currentAcessoSolicitacao.cidade}`}
                            />
                        </div>
                        <div className = "grid grid-cols-2 gap-2 mt-4">
                            <TextGroup
                                title = "Nome"
                                text = {currentAcessoSolicitacao.nomeResponsavel}
                            />
                            <TextGroup
                                title = "CPF"
                                text = {currentAcessoSolicitacao.cpfResponsavel}
                            />
                            <TextGroup
                                title = "E-mail"
                                text = {currentAcessoSolicitacao.email}
                            />
                            <TextGroup
                                title = "Telefone"
                                text = {currentAcessoSolicitacao.telefone}
                            />
                        </div>
                        <div className = "grid grid-cols-2 gap-2 mt-4">
                            <TextGroup
                                title = "Manhã"
                                text = {`${currentAcessoSolicitacao.atendimentoManhaEntrada} - ${currentAcessoSolicitacao.atendimentoManhaSaida}`}
                            />
                            <TextGroup
                                title = "Tarde"
                                text = {`${currentAcessoSolicitacao.atendimentoTardeEntrada} - ${currentAcessoSolicitacao.atendimentoTardeSaida}`}
                            />
                        </div>
                    </>
                )}
            </Modal>
        </>

    )

}

export default ModalSolicitacao