import React from 'react'
import { mdiCheck } from '@mdi/js'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ButtonGroup from '@components/Button/Group'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { ModalSaveProps } from './types'
import { setOpenModalSave } from '../../reducers/agendamentoCadastroHorarioReducer'
import { saveAgendamentoHorario } from '@modules/agendamento/controllers'

const ModalSave: React.FC <ModalSaveProps> = ({ setRedirect }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentData } = useAppSelector(s => s.agendamento)
    const { openModalSave, modalSaveValues } = useAppSelector(s => s.agendamentoCadastroHorario)
    const { requestSaveAgendamentoHorario } = useAppSelector(s => s.requestAgendamento)
    const { guid } = useParams()

    const title = modalSaveValues?.findInBloqueios ? 'Solicitar desbloqueio' : 'Salvar'
    const message = modalSaveValues?.findInBloqueios ? 'solicitar o desbloqueio do agendamento' : 'salvar o agendamento'

    return(

        <Modal title = {title} width = {400} open = {openModalSave} setOpen = {setOpenModalSave}>
            <span className = "modalBoxMessage">{`Deseja ${message} para o dia ${currentData.split('-').reverse().join('/')} às ${modalSaveValues?.hour} na janela ${modalSaveValues?.dock}`}</span>
            <ButtonGroup>
                <Button
                    label = "Cancelar"
                    onClick = {() => dispatch(setOpenModalSave(false))}
                />
                <Button
                    icon = {mdiCheck}
                    loading = {requestSaveAgendamentoHorario.loading}
                    label = "Salvar"
                    status = "success"
                    onClick = {() => {
                        if(!!modalSaveValues){
                            const { hour, dock, findInBloqueios } = modalSaveValues
                            saveAgendamentoHorario(dispatch, guid!, currentData, hour, dock, findInBloqueios, setRedirect, userData!)
                        }
                    }}
                />
            </ButtonGroup>
        </Modal>

    )

}

export default ModalSave