import * as yup from 'yup'

export const formValues = {
    idArmazem: '',
    data: '',
}

export const formSchema = yup.object().shape({
    idArmazem: yup.string().required('Campo obrigatório'),
    data: yup.string().required('Campo obrigatório')
})