import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AcessoSolicitacao } from "../interfaces/AcessoSolicitacao"

interface State {
    acessoSolicitacoes: AcessoSolicitacao[] | null
    currentAcessoSolicitacao: AcessoSolicitacao | null
}

const initialState: State = {
    acessoSolicitacoes: null,
    currentAcessoSolicitacao: null,
}

const acessoSlice = createSlice({
    name: 'acesso',
    initialState,
    reducers: {
        setAcessoSolicitacoes: (state, action: PayloadAction<AcessoSolicitacao[]>) => {
            state.acessoSolicitacoes = action.payload
        },
        setCurrentAcessoSolicitacao: (state, action: PayloadAction<AcessoSolicitacao>) => {
            state.currentAcessoSolicitacao = action.payload
        },
    }
})

export const { setAcessoSolicitacoes, setCurrentAcessoSolicitacao } = acessoSlice.actions
export default acessoSlice.reducer