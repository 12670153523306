import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    openModalAgendamento: boolean
    openModalEditAgendamento: boolean
}

const initialState: State = {
    openModalAgendamento: false,
    openModalEditAgendamento: false,
}

const agendamentoListaSlice = createSlice({
    name: 'agendamentoLista',
    initialState,
    reducers: {
        setOpenModalAgendamento: (state, action: PayloadAction<boolean>) => {
            state.openModalAgendamento = action.payload
        },
        setOpenModalEditAgendamento: (state, action: PayloadAction<boolean>) => {
            state.openModalEditAgendamento = action.payload
        },
    }
})

export const { setOpenModalAgendamento, setOpenModalEditAgendamento } = agendamentoListaSlice.actions
export default agendamentoListaSlice.reducer