const status = [
    { label: 'Agendado', id: 1 },
    { label: 'Checkin', id: 2 },
    { label: 'Fila Entrada', id: 3 },
    { label: 'Balança', id: 4 },
    { label: 'Entrou', id: 5 },
    { label: 'In. Operação', id: 6 },
    { label: 'Fn. Operação', id: 7 },
    { label: 'Saiu', id: 8 },
    { label: 'Faltou', id: 9 },
]

export default status