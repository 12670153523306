import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Armazem } from "@interfaces/Armazem"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"

interface State {
    requestGetArmazens: ResponseDefault<Armazem[]>
    requestGetArmazem: ResponseDefault<Armazem[]>
    requestCreateArmazem: ResponseDefault<any>
    requestEditArmazem: ResponseDefault<any>
    requestRemoveArmazem: ResponseDefault<any>
}

const initialState: State = {
    requestGetArmazens: responseInitialValues,
    requestGetArmazem: responseInitialValues,
    requestCreateArmazem: responseInitialValues,
    requestEditArmazem: responseInitialValues,
    requestRemoveArmazem: responseInitialValues,
}

const requestArmazemSlice = createSlice({
    name: 'requestArmazem',
    initialState,
    reducers: {
        setRequestGetArmazensData: (state: State, action: PayloadAction<ResponsePattern<Armazem[]>>) => {
            state.requestGetArmazens.data = action.payload
            state.requestGetArmazens.loading = false
            state.requestGetArmazens.error = false
        },
        setRequestGetArmazensLoading: (state: State) => {
            state.requestGetArmazens.loading = true
            state.requestGetArmazens.error = false
        },
        setRequestGetArmazensError: (state: State) => {
            state.requestGetArmazens.loading = false
            state.requestGetArmazens.error = true
        },
        setRequestGetArmazensMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetArmazens.message = action.payload
        },
        resetRequestGetArmazens: (state: State) => {
            state.requestGetArmazens = {...responseInitialValues}
        },

        setRequestGetArmazemData: (state: State, action: PayloadAction<ResponsePattern<Armazem[]>>) => {
            state.requestGetArmazem.data = action.payload
            state.requestGetArmazem.loading = false
            state.requestGetArmazem.error = false
        },
        setRequestGetArmazemLoading: (state: State) => {
            state.requestGetArmazem.loading = true
            state.requestGetArmazem.error = false
        },
        setRequestGetArmazemError: (state: State) => {
            state.requestGetArmazem.loading = false
            state.requestGetArmazem.error = true
        },
        setRequestGetArmazemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetArmazem.message = action.payload
        },
        resetRequestGetArmazem: (state: State) => {
            state.requestGetArmazem = {...responseInitialValues}
        },

        setRequestCreateArmazemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateArmazem.data = action.payload
            state.requestCreateArmazem.loading = false
            state.requestCreateArmazem.error = false
        },
        setRequestCreateArmazemLoading: (state: State) => {
            state.requestCreateArmazem.loading = true
            state.requestCreateArmazem.error = false
        },
        setRequestCreateArmazemError: (state: State) => {
            state.requestCreateArmazem.loading = false
            state.requestCreateArmazem.error = true
        },
        setRequestCreateArmazemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateArmazem.message = action.payload
        },
        resetRequestCreateArmazem: (state: State) => {
            state.requestCreateArmazem = {...responseInitialValues}
        },

        setRequestEditArmazemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestEditArmazem.data = action.payload
            state.requestEditArmazem.loading = false
            state.requestEditArmazem.error = false
        },
        setRequestEditArmazemLoading: (state: State) => {
            state.requestEditArmazem.loading = true
            state.requestEditArmazem.error = false
        },
        setRequestEditArmazemError: (state: State) => {
            state.requestEditArmazem.loading = false
            state.requestEditArmazem.error = true
        },
        setRequestEditArmazemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestEditArmazem.message = action.payload
        },
        resetRequestEditArmazem: (state: State) => {
            state.requestEditArmazem = {...responseInitialValues}
        },

        setRequestRemoveArmazemData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveArmazem.data = action.payload
            state.requestRemoveArmazem.loading = false
            state.requestRemoveArmazem.error = false
        },
        setRequestRemoveArmazemLoading: (state: State) => {
            state.requestRemoveArmazem.loading = true
            state.requestRemoveArmazem.error = false
        },
        setRequestRemoveArmazemError: (state: State) => {
            state.requestRemoveArmazem.loading = false
            state.requestRemoveArmazem.error = true
        },
        setRequestRemoveArmazemMessage: (state: State, action: PayloadAction<string>) => {
            state.requestRemoveArmazem.message = action.payload
        },
        resetRequestRemoveArmazem: (state: State) => {
            state.requestRemoveArmazem = {...responseInitialValues}
        },
    }
})

const actions = requestArmazemSlice.actions

export const requestGetArmazensReducers = {
    data: actions.setRequestGetArmazensData,
    loading: actions.setRequestGetArmazensLoading,
    error: actions.setRequestGetArmazensError,
    message: actions.setRequestGetArmazensMessage,
    reset: actions.resetRequestGetArmazens,
}

export const requestGetArmazemReducers = {
    data: actions.setRequestGetArmazemData,
    loading: actions.setRequestGetArmazemLoading,
    error: actions.setRequestGetArmazemError,
    message: actions.setRequestGetArmazemMessage,
    reset: actions.resetRequestGetArmazem,
}

export const requestCreateArmazemReducers = {
    data: actions.setRequestCreateArmazemData,
    loading: actions.setRequestCreateArmazemLoading,
    error: actions.setRequestCreateArmazemError,
    message: actions.setRequestCreateArmazemMessage,
    reset: actions.resetRequestCreateArmazem,
}

export const requestEditArmazemReducers = {
    data: actions.setRequestEditArmazemData,
    loading: actions.setRequestEditArmazemLoading,
    error: actions.setRequestEditArmazemError,
    message: actions.setRequestEditArmazemMessage,
    reset: actions.resetRequestEditArmazem,
}

export const requestRemoveArmazemReducers = {
    data: actions.setRequestRemoveArmazemData,
    loading: actions.setRequestRemoveArmazemLoading,
    error: actions.setRequestRemoveArmazemError,
    message: actions.setRequestRemoveArmazemMessage,
    reset: actions.resetRequestRemoveArmazem,
}

export default requestArmazemSlice.reducer