import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { RelatorioAgendamento } from "../interfaces/RelatorioAgendamento"

interface State {
    requestGetRelatorioAgendamentos: ResponseDefault<RelatorioAgendamento[]>
}

const initialState: State = {
    requestGetRelatorioAgendamentos: responseInitialValues,
}

const requestRelatorioSlice = createSlice({
    name: 'requestRelatorio',
    initialState,
    reducers: {
        setRequestGetRelatorioAgendamentosData: (state: State, action: PayloadAction<ResponsePattern<RelatorioAgendamento[]>>) => {
            state.requestGetRelatorioAgendamentos.data = action.payload
            state.requestGetRelatorioAgendamentos.loading = false
            state.requestGetRelatorioAgendamentos.error = false
        },
        setRequestGetRelatorioAgendamentosLoading: (state: State) => {
            state.requestGetRelatorioAgendamentos.loading = true
            state.requestGetRelatorioAgendamentos.error = false
        },
        setRequestGetRelatorioAgendamentosError: (state: State) => {
            state.requestGetRelatorioAgendamentos.loading = false
            state.requestGetRelatorioAgendamentos.error = true
        },
        setRequestGetRelatorioAgendamentosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetRelatorioAgendamentos.message = action.payload
        },
        resetRequestGetRelatorioAgendamentos: (state: State) => {
            state.requestGetRelatorioAgendamentos = {...responseInitialValues}
        },
    }
})

const actions = requestRelatorioSlice.actions

export const requestGetRelatorioAgendamentosReducers = {
    data: actions.setRequestGetRelatorioAgendamentosData,
    loading: actions.setRequestGetRelatorioAgendamentosLoading,
    error: actions.setRequestGetRelatorioAgendamentosError,
    message: actions.setRequestGetRelatorioAgendamentosMessage,
    reset: actions.resetRequestGetRelatorioAgendamentos,
}

export default requestRelatorioSlice.reducer