import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { ContainerProps } from './types'

const PdfContainer: React.FC <ContainerProps> = ({ children, style }) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            width: '100%',
            ...style
        }
    })

    return(

        <View style = {styles.container}>
            {children}
        </View>

    )

}

export default PdfContainer